import { message } from "antd";
import axiosInstance from "../utils/axios";

export const fetchSearchPage = async (query, setData) => {
    try {
        await axiosInstance.get(`/search-page/getAllSearchPage?${query}`)
          .then((res) => {
            const data = res?.data;
            setData(data);
          })
          .catch(() => message.error("Error server!"));
    } catch (error) {
        console.log(error);
    }
};