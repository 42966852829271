import { useEffect, useState } from "react"
import { fetchAll, update } from "../../api/iframePage";
import { Button, Form, Input, message } from "antd";

export default function AdminPage() { 
    const [form] = Form.useForm();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenKiem, setIsOpenKiem] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [data, setData] = useState([])

    const onSubmit = async (values) => {
        await update(1, values, message);
        setIsOpen(false);
        window.location.reload();
    }

    const onSubmitKiem = async (values) => {
        await update(2, values, message);
        setIsOpenKiem(false);
        window.location.reload();
    }

    const onSubmitCong = async (id, values) => {
        await update(id, values, message);
        if(id == 3) {setIsOpen1(false);}
        if(id == 4) {setIsOpen2(false);}
        if(id == 5) {setIsOpen3(false);}
        if(id == 6) {setIsOpen4(false);}
        if(id == 7) {setIsOpen5(false);}
        window.location.reload();
    }

    useEffect(()=> {
        fetchAll(setData);
    }, [isOpen, isOpenKiem, isOpen1, isOpen2, isOpen3, isOpen4, isOpen5])

    return (
        <div className="pt-10">
            <p className="text-center w-full font-bold text-4xl">TRANG QUẢN TRỊ CỦA ECOMMERE</p>

            <div className="flex py-5">
                {isOpen ?
                    <Form                        
                        layout={"vertical"}
                        colon={false}
                        form={form}
                        initialValues={data?.data?.[0]}
                        onFinishFailed={(e) => console.log(e)}
                        onFinish={onSubmit}>

                        <Form.Item
                            name="iframe_page_link"
                            label={"Link nhúng trang công cụ"}
                            rules={[{ required: true, message: "Vui lòng nhập!" }]}
                        >
                            <Input size="lage" placeholder="Text"/>
                        </Form.Item>

                        <Button htmlType={"submit"}>Xong</Button>
                    </Form>
                    :
                    <>
                        <p className="w-full font-semibold text-2xl">Link nhúng trang công cụ:  {data?.data?.[0]?.iframe_page_link}</p>
                        <Button onClick={()=>{setIsOpen5(false); setIsOpen(true); setIsOpen1(false); setIsOpen2(false); setIsOpen3(false); setIsOpen4(false); setIsOpenKiem(false)}}>Sửa</Button>
                    </>
                }
            </div>
            <div className="flex py-5">
                {isOpenKiem ?
                    <Form                        
                        layout={"vertical"}
                        colon={false}
                        form={form}
                        initialValues={data?.data?.[1]}
                        onFinishFailed={(e) => console.log(e)}
                        onFinish={onSubmitKiem}>

                        <Form.Item
                            name="iframe_page_link"
                            label={"Link nhúng trang kiếm tiền"}
                            rules={[{ required: true, message: "Vui lòng nhập!" }]}
                        >
                            <Input size="lage" placeholder="Text"/>
                        </Form.Item>

                        <Button htmlType={"submit"}>Xong</Button>
                    </Form>
                    :
                    <>
                        <p className="w-full font-semibold text-2xl">Link nhúng trang kiếm tiền:  {data?.data?.[1]?.iframe_page_link}</p>
                        <Button onClick={()=>{setIsOpen5(false); setIsOpen(false); setIsOpen1(false); setIsOpen2(false); setIsOpen3(false); setIsOpen4(false); setIsOpenKiem(true)}}>Sửa</Button>
                    </>
                }
            </div>
            <div className="flex py-5">
                {isOpen1 ?
                    <Form                        
                        layout={"vertical"}
                        colon={false}
                        form={form}
                        initialValues={data?.data?.[2]}
                        onFinishFailed={(e) => console.log(e)}
                        onFinish={(e)=>onSubmitCong(3, e)}>

                        <Form.Item
                            name="iframe_page_link"
                            label={"Link nhúng trang clone 1"}
                            rules={[{ required: true, message: "Vui lòng nhập!" }]}
                        >
                            <Input size="lage" placeholder="Text"/>
                        </Form.Item>

                        <Button htmlType={"submit"}>Xong</Button>
                    </Form>
                    :
                    <>
                        <p className="w-full font-semibold text-2xl">Link nhúng trang /cong-cu/3:  {data?.data?.[2]?.iframe_page_link}</p>
                        <Button onClick={()=>{setIsOpen5(false); setIsOpen(false); setIsOpen1(true); setIsOpen2(false); setIsOpen3(false); setIsOpen4(false); setIsOpenKiem(false)}}>Sửa</Button>
                    </>
                }
            </div>
            <div className="flex py-5">
                {isOpen2 ?
                    <Form                        
                        layout={"vertical"}
                        colon={false}
                        form={form}
                        initialValues={data?.data?.[3]}
                        onFinishFailed={(e) => console.log(e)}
                        onFinish={(e)=>onSubmitCong(4, e)}>
                        <Form.Item
                            name="iframe_page_link"
                            label={"Link nhúng trang clone 2"}
                            rules={[{ required: true, message: "Vui lòng nhập!" }]}
                        >
                            <Input size="lage" placeholder="Text"/>
                        </Form.Item>

                        <Button htmlType={"submit"}>Xong</Button>
                    </Form>
                    :
                    <>
                        <p className="w-full font-semibold text-2xl">Link nhúng trang /cong-cu/4:  {data?.data?.[3]?.iframe_page_link}</p>
                        <Button onClick={()=>{setIsOpen5(false); setIsOpen(false); setIsOpen1(false); setIsOpen2(true); setIsOpen3(false); setIsOpen4(false); setIsOpenKiem(false)}}>Sửa</Button>
                    </>
                }
            </div>
            <div className="flex py-5">
                {isOpen3 ?
                    <Form                        
                        layout={"vertical"}
                        colon={false}
                        form={form}
                        initialValues={data?.data?.[4]}
                        onFinishFailed={(e) => console.log(e)}
                        onFinish={(e)=>onSubmitCong(5, e)}>

                        <Form.Item
                            name="iframe_page_link"
                            label={"Link nhúng trang clone 3"}
                            rules={[{ required: true, message: "Vui lòng nhập!" }]}
                        >
                            <Input size="lage" placeholder="Text"/>
                        </Form.Item>

                        <Button htmlType={"submit"}>Xong</Button>
                    </Form>
                    :
                    <>
                        <p className="w-full font-semibold text-2xl">Link nhúng trang /cong-cu/5:  {data?.data?.[4]?.iframe_page_link}</p>
                        <Button onClick={()=>{setIsOpen5(false); setIsOpen(false); setIsOpen1(false); setIsOpen2(false); setIsOpen3(true); setIsOpen4(false); setIsOpenKiem(false)}}>Sửa</Button>
                    </>
                }
            </div>
            <div className="flex py-5">
                {isOpen4 ?
                    <Form                        
                        layout={"vertical"}
                        colon={false}
                        form={form}
                        initialValues={data?.data?.[5]}
                        onFinishFailed={(e) => console.log(e)}
                        onFinish={(e)=>onSubmitCong(6, e)}>

                        <Form.Item
                            name="iframe_page_link"
                            label={"Link nhúng trang clone 4"}
                            rules={[{ required: true, message: "Vui lòng nhập!" }]}
                        >
                            <Input size="lage" placeholder="Text"/>
                        </Form.Item>

                        <Button htmlType={"submit"}>Xong</Button>
                    </Form>
                    :
                    <>
                        <p className="w-full font-semibold text-2xl">Link nhúng trang /cong-cu/6:  {data?.data?.[5]?.iframe_page_link}</p>
                        <Button onClick={()=>{setIsOpen5(false); setIsOpen(false); setIsOpen1(false); setIsOpen2(false); setIsOpen3(false); setIsOpen4(true); setIsOpenKiem(false)}}>Sửa</Button>
                    </>
                }
            </div>
            <div className="flex py-5">
                {isOpen5 ?
                    <Form                        
                        layout={"vertical"}
                        colon={false}
                        form={form}
                        initialValues={data?.data?.[6]}
                        onFinishFailed={(e) => console.log(e)}
                        onFinish={(e)=>onSubmitCong(7, e)}>

                        <Form.Item
                            name="iframe_page_link"
                            label={"Link nhúng trang clone 5"}
                            rules={[{ required: true, message: "Vui lòng nhập!" }]}
                        >
                            <Input size="lage" placeholder="Text"/>
                        </Form.Item>

                        <Button htmlType={"submit"}>Xong</Button>
                    </Form>
                    :
                    <>
                        <p className="w-full font-semibold text-2xl">Link nhúng trang /cong-cu/7:  {data?.data?.[6]?.iframe_page_link}</p>
                        <Button onClick={()=>{setIsOpen5(true); setIsOpen(false); setIsOpen1(false); setIsOpen2(false); setIsOpen3(false); setIsOpen4(false); setIsOpenKiem(false)}}>Sửa</Button>
                    </>
                }
            </div>
        </div>
    )
}