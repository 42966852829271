import axiosInstance from "../utils/axios";

export const getOrderByUser = async (id, query, setData) => {
    try {
        await axiosInstance.get(`/order/getByUserId/${id}?${query}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}