import { useLocation, useNavigate, useParams } from "react-router-dom"
import ImageGallery from "../../component/imageGallery";
import ProductInfor from "../../section/productInfor";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { message } from "antd";
import parse from 'html-react-parser';
import { getAll, getAllIndexPage } from "../../api/category";
import ItemProduct from "../../section/itemProduct";
import { ArrowLeftOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";
import more from "../../assets/icon/more.png"
import * as CONSTANTS from "../../utils/constants";
import freeship from "../../assets/image/freeship.png";
import { getCookie } from "../../utils/localStorageHandle";

export default function ProductIsMobile() {
    const param = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [product, setProduct] = useState([]);
    const [productImage, setProductImage] = useState([]);
    const [isFile, setIsFile] = useState(false);
    const [categoryIndex, setCategoryIndex] = useState([]);
    const [read, setRead] = useState(false);
    const [category, setCategory] = useState([]);
    const [cart, setCart] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    
    const fetchProduct = async () => {
        await axiosInstance.get(`/product/getBySlug/${param?.slug}`)
        .then((res) => {
            const data = res?.data?.data;
            setProduct(data);
            const image = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data;
            const slide = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'slide')?.[0]?.data;
            if(JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data) { setIsFile(true) }
            setProductImage([image, ...slide]);
        })
        .catch(() => message.error("Error server!"));
    };

    useEffect(() => {
        fetchProduct();
        getAllIndexPage(setCategoryIndex);
    }, [isFile]);

    useEffect(() => {
        getAllIndexPage(setCategoryIndex);
        getAll("page=1&pageSize=100", setCategory);
    }, []);

    useEffect(() => {
        setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
    }, []);
    
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 1); // Adjust the threshold as needed
        };

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrolled]);

    return (
        <div>
            <div className="bg-white">
                {isScrolled ? (
                    <div className="bg-white fixed w-full shadow top-0 z-10 flex items-center justify-between py-2 px-4">
                        <ArrowLeftOutlined 
                            className="p-2 text-white bg-blue-600 rounded-full opacity-70" 
                            onClick={() => navigate(-1)} 
                        /> 
                        <div>
                            {cookies?.user && (
                                <div style={{ position: 'relative' }}>
                                    <div 
                                        className="absolute top-0 right-10 bg-blue-600 rounded-full z-10 opacity-70" 
                                        onClick={() => navigate("/gio-hang")}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <ShoppingCartOutlined className="p-2 text-white" />
                                    </div>
                                    {cart && cart.length > 0 && (
                                        <div 
                                            className="w-4 h-4 text-center" 
                                            style={{
                                                position: 'absolute',
                                                top: '-4px',
                                                right: '38px',
                                                backgroundColor: 'red',
                                                color: 'white',
                                                borderRadius: '50%',
                                                fontSize: '0.75rem', 
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 20 
                                            }}
                                        >
                                            {cart.length}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div 
                                className="p-2 top-0 right-4 bg-blue-600 rounded-full z-10 opacity-70" 
                                onClick={() => {
                                    navigator.clipboard.writeText("https://cuahangykhoa.com" + location?.pathname);
                                    message.success("Sao chép liên kết thành công!");
                                }}
                            >
                                <img src={more} alt="More options" width={15} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="relative bg-white">
                        <div className={`${isScrolled ? 'fixed bg-blue-200 left-0 w-full shadow z-10' : ''}`}>
                            <div className="absolute top-8 left-4 bg-blue-600 rounded-full z-10 opacity-70" onClick={()=>navigate(-1)}>
                                <ArrowLeftOutlined className="p-2 text-white"/> 
                            </div>
                            {cookies?.user && (
                                <div style={{ position: 'relative' }}>
                                    <div 
                                        className="absolute top-8 right-14 bg-blue-600 rounded-full z-10 opacity-70" 
                                        onClick={() => navigate("/gio-hang")}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <ShoppingCartOutlined className="p-2 text-white" />
                                    </div>
                                    {cart && cart.length > 0 && (
                                        <div 
                                            className="w-4 h-4 text-center" 
                                            style={{
                                                position: 'absolute',
                                                top: '28px',
                                                right: '52px',
                                                backgroundColor: 'red',
                                                color: 'white',
                                                borderRadius: '50%',
                                                fontSize: '0.8rem',
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 20, // Ensure the badge is above other elements
                                            }}
                                        >
                                            {cart.length}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div 
                                className="absolute top-8 right-4 bg-blue-600 rounded-full z-10 opacity-70 p-2" 
                                onClick={()=>{navigator.clipboard.writeText("https://cuahangykhoa.com" + location?.pathname); message.success("Sao chép liên kết thành công!")}}
                            >
                                <img src={more} width={15}/>
                            </div>
                        </div>
                    </div>
                )}
                <div className="mt-[-20px]">
                    <ImageGallery product_image={productImage} />
                </div>
                <div className="bg-white">
                    <ProductInfor product={product[0]} attribute={product?.attribute} isFile={isFile}/>
                    
                    <div className="p-5 ml-2">
                        <p className="text-[16px] font-semibold">Thông tin chi tiết</p>
                        {parse(String(product[0]?.product_information?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                    </div>
                </div>
                    
                <div className="bg-white p-5 rounded-lg col-span-3 mt-2">
                    <p className="text-[16px] font-semibold">Đặc điểm nổi bật</p>
                    {parse(String(product[0]?.product_special?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                </div>

                <div className="bg-white p-5 rounded-lg col-span-3">
                    <div>
                        <p className="text-[16px] font-semibold">Chính sách trả hàng</p>
                        <p className="pt-1">Xem ở mục mô tả sản phẩm</p>
                    </div>
                    <div className="py-2">
                        <p className="text-[16px] font-semibold">Vận chuyển</p>
                        <div className="flex items-center pt-1">
                            <div className="flex items-center">
                                <img src={freeship} width={30} height={10}/>
                            </div>
                            <p className=" pl-2">Vào mục mã giảm giá để lấy mã freeship</p>
                        </div>
                    </div>
                </div>
                    
                <div className="bg-white p-5 rounded-lg col-span-3 mt-2">
                    <p className="text-[16px] font-semibold pb-2">Mô tả sản phẩm</p>
                    {read ? (
                        <div>
                            {parse(String(product[0]?.product_description?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                        </div>
                    ) : (
                        <div className="truncate h-[100px]">
                            {parse(String(product[0]?.product_description?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                        </div>
                    )}
                    <div className="flex justify-center pt-5">
                        <button className="border border-blue-600 px-4 py-2 text-lg font-semibold text-blue-600" onClick={()=> setRead(!read)}>
                            {!read ? "Xem thêm" : "Rút gọn"}
                        </button>
                    </div>
                </div>
            </div>
            <div className='my-2 p-5 bg-white rounded-xl'>
                <div className='flex justify-between items-center'>
                    <div className='flex items-center'>
                        <img src={categoryIndex?.data?.[0]?.category_index_image} className='h-10'/>
                    </div>
                    <a href={`/danh-muc-trang-chu/${categoryIndex?.data?.[0]?.category_index_id}`}>
                        <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                    </a>
                </div>
                <div className='py-8'>
                    <ItemProduct product={categoryIndex?.data?.[0]?.product} grid={3} titleSize={13} heightTitle={10}/>
                </div>
            </div>

            <div className='p-5 bg-white rounded-xl'>
                <div className='grid grid-cols-5 gap-4'>
                    {category?.data?.map((_,i)=> (
                        <a href={`/danh-muc-san-pham/${_?.category_slug}`}>
                            <div className='flex justify-center'>
                                <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                            </div>
                            <p className='text-center pt-1 text-[10px] font-semibold text-black'>{_?.category_name}</p>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}