import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { useDevice } from "../../hooks";
import { fetchAll } from "../../api/iframePage";
import { Col, Row } from "antd";
import { getAll } from '../../api/category';

export default function ToolPage() {
    const { isMobile } = useDevice();
    const [data, setData] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const [categoryMenu, setCategoryMenu] = useState([]);

    useEffect(() => {
        fetchAll(setData);
        getAll("page=1&pageSize=100", setCategoryMenu);
    }, []);
    
    useEffect(() => {
        const handleScroll = () => {
            setIsFixed(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="max-w-screen-2xl mx-auto">
            <Row>
                {!isMobile && (
                    <Col xs={24} xl={4} className='pr-2'>
                        <div 
                            className={`my-2 p-5 bg-white rounded-lg h-[100vh] ${isFixed ? "fixed top-0" : "overflow-y-visible"}`} 
                            style={{
                                scrollbarWidth: 'none'
                            }}
                        >
                            {categoryMenu?.data?.map((_,i)=> (
                                <a href={`/danh-muc-san-pham/${_?.category_slug}`} className='flex items-center p-2 hover:bg-gray-200 rounded-xl'>
                                    <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                    <p className='pl-2 text-black w-[150px]'>{_?.category_name}</p>
                                </a>
                            ))}
                        </div>
                    </Col>
                )}
                <Col xs={24} xl={isMobile ? 24 : 20}>
                    <div className="flex justify-center">
                        <iframe
                            className={`${isMobile ? "pt-16" : ""} py-2 h-screen`}
                            src={data?.data?.[0]?.iframe_page_link} 
                            style={{ width: '100%', border: 'none' }}
                            sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
                            allowFullScreen
                            title="Iframe Example"
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}