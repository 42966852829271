
import ItemCategory from '../../section/itemCategory';
import { useParams } from 'react-router-dom';
import ItemProduct from '../../section/itemProduct';
import { useEffect, useState } from 'react';
import { getAll, getBySlug } from '../../api/category';
import ItemProductGrid from '../../section/itemProductGrid';
import { Col, Row } from 'antd';
import { useDevice } from '../../hooks';
import CategoryIsMobile from './categoryIsMobile';

export default function Category() {
    const { isMobile } = useDevice();
    const param = useParams();
    const [category, setCategory] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const [categoryMenu, setCategoryMenu] = useState([]);

    useEffect(()=> {
        getBySlug(param?.slug, setCategory);
        getAll("page=1&pageSize=100", setCategoryMenu);
    }, [])
    
    useEffect(() => {
        const handleScroll = () => {
            setIsFixed(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <div className="max-w-screen-2xl mx-auto">
            {isMobile ? <CategoryIsMobile /> : (
                <Row>
                    <Col xs={24} xl={4} className='pr-2'>
                        <div 
                            className={`my-2 p-5 bg-white rounded-lg h-[100vh] ${isFixed ? "fixed top-0" : "overflow-y-visible"}`} 
                            style={{
                                scrollbarWidth: 'none'
                            }}
                        >
                            {categoryMenu?.data?.map((_,i)=> (
                                <a href={`/danh-muc-san-pham/${_?.category_slug}`} className='flex items-center p-2 hover:bg-gray-200 rounded-xl'>
                                    <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                    <p className='pl-2 text-black w-[150px]'>{_?.category_name}</p>
                                </a>
                            ))}
                        </div>
                    </Col>
                    <Col xs={24} xl={20}>
                        <div className='my-2 p-5 bg-white rounded-xl'>
                            <h1 className='text-[28px] font-semibold'>Danh mục sản phẩm</h1>
                        </div>

                        <div className='my-2 p-5 bg-white rounded-xl'>
                            <h2 className='text-[20px] font-semibold'>Khám phá theo danh mục</h2>
                            <div className='py-5'>
                                <ItemCategory category={category?.data} />
                            </div>
                        </div>
                        
                        {category?.data?.dataChild?.map((_, i) => (
                            <div className='my-2 p-5 bg-white rounded-xl'>
                                <div className='flex justify-between'>
                                    <h3 className='font-semibold text-lg'>{_?.category_child_name}</h3>
                                    <a href={`/danh-muc-con/${_?.category_child_slug}`}>
                                        <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                                    </a>
                                </div>
                                <div className='py-4'>
                                    <ItemProduct
                                        product={_?.product} 
                                        grid={5} 
                                        titleSize={13}
                                        heightTitle={10}
                                    />
                                </div>
                            </div>
                        ))}
                    </Col>
                </Row>
            )}
        </div>
    )
}