export const parseSafe = (str) => {
  try {
    const result = JSON.parse(str)

    return result
  } catch (e) {
    return undefined
  }
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)

    reader.onerror = (error) => reject(error)
})

export const timeDifference = (pastTime) => {
  try {
    const currentTime = new Date();
    const timeDiffInMs = currentTime.getTime() - pastTime.getTime();

    const days = Math.floor(timeDiffInMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiffInMs % (1000 * 60)) / 1000);
    
    return {
      days,
      hours,
      minutes,
      seconds
    };
  } catch (e) {
    return undefined
  }
}

export const discountPrice = (total, discount) => {
  return total - (total / 100) * discount;
};

export const calculateTotalPrice = (cart) => {
  let total = 0;
  if (cart != undefined) {
    cart.map((item) => {
      total += item?.totalprice;
    });
  }
  return total;
};

export const checkSameDate = (create_at) => {
  const createdAt = new Date(create_at);
  const today = new Date();
  
  const isSameDate = createdAt.toDateString() === today.toDateString();

  return isSameDate;
}

export const PickWareHouse = (province) => {
  const provinceB = [
    "Quảng Nam",
    "Quảng Ngãi",
    "Đà Nẵng",
    "Thừa Thiên Huế",
    "Thanh Hóa",
    "Nghệ An",
    "Hà Tĩnh",
    "Quảng Bình",
    "Quảng Trị",
    "Kon Tum",
    "Gia Lai",
    "Đắk Lắk",
    "Hà Nội",
    "Hải Phòng",
    "Quảng Ninh",
    "Bắc Ninh",
    "Bắc Giang",
    "Thái Nguyên",
    "Vĩnh Phúc",
    "Phú Thọ",
    "Hòa Bình",
    "Sơn La",
    "Điện Biên",
    "Lai Châu",
    "Lào Cai",
    "Yên Bái",
    "Tuyên Quang",
    "Hà Giang",
    "Cao Bằng",
    "Bắc Kạn",
    "Lạng Sơn"
  ];

  if(provinceB.includes(province)){
    return {
      pick_province: "Kho Quảng Nam",
      from_district_id: 1631
    };
  } else {
    return {
      pick_province: "Kho Hồ Chí Minh",
      from_district_id: 1461
    }
  }
};