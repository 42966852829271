import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { useDevice } from "../../hooks";
import { fetchSearchPage } from "../../api/searchPage";
import ItemProductGrid from "../../section/itemProductGrid";
import { Empty } from "antd";

export default function SearchPage() {
    const { isMobile } = useDevice();
    const params = useParams();
    const [product, setProduct] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 100,
    });

    useEffect(() => {
        let querySearch = `keyword=${params?.keyword}&page=${pagination?.page}&pageSize=${pagination?.pageSize}`
        fetchSearchPage(querySearch, setProduct);
    }, [pagination]);

    return (
        <div className="max-w-screen-2xl mx-auto">
            <div className={`${isMobile ? "mt-14" : "mt-5"} p-5 bg-white`}>
                <p className="text-xl">Từ khóa tìm kiếm: <span className="font-semibold">{params?.keyword}</span></p>
            </div>
            <div className="my-2 p-5 bg-white">
                {product?.data?.length > 0 ? (
                    <ItemProductGrid
                        product={product?.data} 
                        grid={isMobile ? 2 : 5} 
                        titleSize={13}
                        heightTitle={10}
                    />
                ): (
                    <Empty />
                )}
            </div>
        </div>
    )
}