import { Table, message, Button, Row, Col, Pagination, Image } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";
import { getAllCategoryIndex } from "../../../api/category";

export default function CategoryIndexManage() {
  const [categoryIndex, setCategoryIndex] = useState([]);

  useEffect(() => {
    getAllCategoryIndex(setCategoryIndex);
  }, []);

  const columns = [
    {
      title: <div>ID</div>,
      key: "category_index_id",
      dataIndex: "category_index_id",
      width: 100,
      sorter: (a, b) => a.category_index_id - b.category_index_id,
      render: (_, record) => <div>{record?.category_index_id}</div>,
    },
    {
      title: <div>Ảnh</div>,
      key: "category_index_image",
      dataIndex: "category_index_image",
      width: 150,
      render: (_, record) => <div><Image src={record?.category_index_image} width={150} height={100}/></div>,
    },
    {
      key: "operation",
      dataIndex: "operation",
      width: 50,
      render: (_, record) => {
        return (
          <Link
            to={`/admin/quan-ly-danh-muc-trang-chu/${record?.category_index_id}`}
            className={"text-[var(--blue)]"}
          >
            <EditOutlined />
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Row gutter={10} className={"mb-[8px]"}>
          <Col flex={1}>
            <div className={"text-[20px] font-medium"}>Danh mục trang chủ</div>
          </Col>
          <Col>
            <Link to={"/admin/quan-ly-danh-muc-trang-chu/tao-moi"}>
              <Button type={"primary"} onClick={() => {}}>
                Tạo
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={categoryIndex?.data}
          columns={columns}
          pagination={false}
        />
      </div>
    </>
  );
}
