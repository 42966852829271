import axiosInstance from "../utils/axios";

export const getBySlug = async (slug, query, setData) => {
    try {
        await axiosInstance.get(`/category-child/getBySlug/${slug}?${query}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}