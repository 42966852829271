
import ItemCategory from '../../section/itemCategory';
import { useParams } from 'react-router-dom';
import ItemProduct from '../../section/itemProduct';
import { useEffect, useState } from 'react';
import { getBySlug } from '../../api/categoryChild';
import { Col, Pagination, Row, Select } from 'antd';
import { getAll } from '../../api/category';
import ItemProductGrid from '../../section/itemProductGrid';

export default function CategoryChildIsMobile() {
    const param = useParams();
    const [categoryChild, setCategoryChild] = useState([]);
    const [topBuy, setTopBuy] = useState(false);
    const [price, setPrice] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(10);

    useEffect(() => {
        let query = `page=1&pageSize=${itemsToShow}`;
        if (topBuy) { query += `&topBuy=1`; }
        if (price > 0) { query += `&filPrice=${price}`; }
        getBySlug(param.slug, query, setCategoryChild);
    }, [itemsToShow, topBuy, price]);

    const handleLoadMore = () => {
        setItemsToShow(prev => prev + 10);
    };

    return (
        <div className="max-w-screen-2xl mx-auto pt-20">
            
            <div className='my-2 p-5 bg-white rounded-xl'>
                <h1 className='text-[28px] font-semibold'>{categoryChild?.data?.category_child_name}</h1>
            </div>
            
            <div className='my-2 p-5 bg-white rounded-xl'>
                <div className='flex items-center'>
                    <div>
                        <button className={`border px-4 py-1 mx-4 font-normal rounded-lg ${topBuy ? "bg-blue-500 text-white " : ""}`} onClick={()=>{setTopBuy(!topBuy); setPrice(0)}}>Bán Chạy</button>
                        <Select
                            className="w-[180px]"
                            onChange={(e)=>{setPrice(e); setTopBuy(false)}}
                            options={[
                                {
                                    label: "Giá",
                                    value: 0
                                },
                                {
                                    label: "Giá: Từ thấp đến cao",
                                    value: 1
                                },
                                {
                                    label: "Giá: Từ cao đến thấp",
                                    value: 2
                                }
                            ]}
                            value={price}
                        />
                    </div>
                </div>
                <div className='pt-4 pb-10'>
                    <ItemProductGrid
                        product={categoryChild?.data?.dataProduct} 
                        grid={2} 
                        titleSize={13}
                        heightTitle={10}
                    />
                </div>
                {itemsToShow < categoryChild?.total && (
                    <button className='border px-4 py-2 font-semibold text-lg text-blue-600 border-blue-600 hover:bg-blue-100' onClick={handleLoadMore}>Xem thêm</button>
                )}
            </div>
        </div>
    )
}