import { Table, message, Button, Row, Col, Pagination, Image } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";

export default function WareHouseManage() {
  const [wareHouse, setWareHouse] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const fetchWareHouse = async () => {
    await axiosInstance.get(`/warehouse/getAll`, {params: pagination})
      .then((res) => {
        const data = res?.data;
        setWareHouse(data);
      })
      .catch(() => message.error("Error server!"));
  };

  useEffect(() => {
    fetchWareHouse();
  }, [pagination]);

  const columns = [
    {
      title: <div>ID</div>,
      key: "warehouse_id",
      dataIndex: "warehouse_id",
      width: 100,
      sorter: (a, b) => a.warehouse_id - b.warehouse_id,
      render: (_, record, i) => <div>{i+1}</div>,
    },
    {
      title: <div>Tên sản phẩm</div>,
      key: "product_name",
      dataIndex: "product_name",
      width: 150,
      ...SearchProps("product_name"),
      render: (_, record) => <div>{record?.product_name}</div>,
    },
    {
      title: <div>Thuộc tính</div>,
      key: "values",
      dataIndex: "values",
      width: 150,
      render: (_, record) => <div>{record?.values}</div>,
    },
    {
      title: <div>Số lượng</div>,
      key: "total",
      dataIndex: "total",
      width: 150,
      sorter: (a, b) => a.total - b.total,
      render: (_, record) => <div>{record?.total}</div>,
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
      key: "create_at",
      dataIndex: "create_at",
      width: 100,
      sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
      render: (_, record) => {
        return (
          <div className={"cursor-pointer text-[14px] font-normal"}>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.create_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      key: "operation",
      dataIndex: "operation",
      width: 50,
      render: (_, record) => {
        return (
          <Link
            to={`/admin/quan-ly-kho-hang/${record?.warehouse_id}`}
            className={"text-[var(--blue)]"}
          >
            <EditOutlined />
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Row gutter={10} className={"mb-[8px]"}>
          <Col flex={1}>
            <div className={"text-[20px] font-medium"}>Quản lý kho hàng</div>
          </Col>
          <Col>
            <Link to={"/admin/quan-ly-kho-hang/tao-moi"}>
              <Button type={"primary"} onClick={() => {}}>
                Tạo
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={wareHouse?.data}
          columns={columns}
          pagination={false}
        />
        <Pagination
          className="flex justify-center absolute inset-x-0 bottom-10"
          current={pagination.page}
          total={wareHouse?.total}
          pageSize={pagination.pageSize}
          showSizeChanger
          onChange={(p, ps)=> {
            setPagination({
              page: p,
              pageSize: ps
            })
          }}
        />
      </div>
    </>
  );
}
