import { Form, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axiosInstance from "../../../utils/axios";
import ProductForm from "../../../component/productForm";

export default function ProductManageDetail() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const params = useParams();
  const id = params?.id;
  const [cookies, setCookie, removeCookie] = useCookies(['admin']);

  const fetchProduct = async () => {
    await axiosInstance.get(`/product/getById/${params?.id}`)
      .then((res) => {
        const data = res?.data?.data;
        data[0].product_image_slide = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'slide')?.[0]?.data;
        if(JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data){
          data[0].product_image_file = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data;
        }
        data[0].product_image = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data;

        const result = {};
        data?.attribute?.forEach((item, i) => {
          const pairs = item?.values_attribute?.split('; ').map(pair => pair.split(': '));
          const attributeKey = `attribute_${i}`;
          result[attributeKey] = []; // Khởi tạo mảng cho từng attribute
      
          pairs?.forEach(([id, value], index) => {
              result[attributeKey].push(parseInt(id, 10)); // Thêm id vào mảng
              result[`${attributeKey}_${index}`] = value.trim(); // Gán value
          });
        });

        const output = data?.attribute.map((item, index) => {
          const pairs = item.values?.split('; ').map(pair => pair.split(': '));
          return pairs?.map(([type], i) => ({
              id: result[`attribute_${index}`][i],
              type: type.trim()     
          }));
        });
        data[0] = { ...data[0], ...result, attribute: output };

        setInitialValues(data);
      });
  };

  const onSubmit = async (data) => {
    try {
      const mergedAttributes = Object.keys(data)
        .filter(key => key.startsWith('attribute_'))
        .map(attrKey => {
            const attrId = attrKey.split('_')[1];
            const isParent = attrKey.split('_')[2] === undefined;
            const values = [];
    
            if (isParent) {
                const childValues = data[attrKey]; 
                childValues?.forEach((childValue, index) => {
                    const value = data[`attribute_${attrId}_${index}`];
                    values.push({ id: childValue, value: value });
                });
            }
    
            return {
                attribute: parseInt(attrId),
                values: values
            };
        }).filter(attr => attr.values.length > 0);
        
      const mergeProductImage = [
        { type: "image", data: data.product_image },
        { type: "slide", data: data.product_image_slide }
      ];

      if (data.product_image_file) {
        mergeProductImage.push({ type: "file", data: data.product_image_file });
      }
  
      const result = {
        product_name: data.product_name,
        product_slug: data.product_slug,
        product_image: JSON.stringify(mergeProductImage),
        product_link: data.product_link,
        category_child_id: data.category_child_id,
        product_price: data.product_price,
        product_discount: data.product_discount,
        price_mkt: data.price_mkt,
        product_description: data.product_description,
        product_information: data.product_information,
        product_special: data.product_special,
        attributes: mergedAttributes
      };

      if (id && id !== "tao-moi") {
        await axiosInstance.post(`/product/update/${id}`, result)
          .then((res) => {
            message.success(String(res?.data?.message));
          });
      } else {
        await axiosInstance.post(`/product/create`, result)
          .then((res) => {
            message.success(String(res?.data?.message));
          });
      }
      navigate("/admin/quan-ly-san-pham");
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    if (id && id !== "tao-moi") fetchProduct();
    form.resetFields();
  }, [form, id]);

  return (
    <ProductForm
      id={id !== "create" ? id : undefined}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
}
