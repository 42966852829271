import {
    Form,
    Row,
    Input,
    Space,
    Button,
    message,
    InputNumber,
    Select,
    Modal,
  } from "antd";
  import { useEffect, useState } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import { ExclamationCircleOutlined } from "@ant-design/icons";
  import CustomUpload from "../customUpload";
import axiosInstance from "../../utils/axios";
import CustomSunEditor from "../customSunEditor";
  
  export default function ProductForm({
    id = "",
    initialValues = {},
    onSubmit = () => {},
  }) {
    const navigate = useNavigate();
    const [form] = Form.useForm();  
    const [categoryChild, setCategoryChild] = useState([]);
    const [attribute, setAttribute] = useState([]);
    const [selectAttribute, setSelectAttribute] = useState([]);
    const [editorValue, setEditorValue] = useState('');
    const [editorInfor, setEditorInfor] = useState('');
    const [editorSpec, setEditorSpec] = useState('');
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 100,
    });
    const [paginationAttribute, setPaginationAttribute] = useState({
      page: 1,
      pageSize: 100,
    });
  
    const fetchCategoryChild = async () => {
      await axiosInstance.get(`/category-child/getAll`, {params: pagination})
        .then((res) => {
          const data = res?.data;
          setCategoryChild(data);
        })
        .catch(() => message.error("Error server!"));
    };
  
    const fetchAttribute = async () => {
      await axiosInstance.get(`/attribute/getAll`, {params: paginationAttribute})
        .then((res) => {
          const data = res?.data;
          setAttribute(data);
        })
        .catch(() => message.error("Error server!"));
    };

    const handleChange = (selectedIds, index) => {
    
      const selectedOptions = attribute?.data
        .filter(item => selectedIds.includes(item.attribute_id))
        .map(({ attribute_id, type }) => ({ id: attribute_id, type }));
      
      setSelectAttribute(prev => {
        const newAttributes = [...prev];
        newAttributes[index] = selectedOptions; 
        return newAttributes;
      });
    };
  
    const deleteProduct = async () => {
      await axiosInstance.delete(`/product/delete/${id}`)
        .then((res) => message.success(String(res?.data?.message)))
    };
  
    const removeProduct = async () => {
      try {
        await deleteProduct();
        return navigate("/admin/quan-ly-danh-muc-con");
      } catch (err) {
        console.log(err.message);
      }
    };
  
    const confirmDeleteBusiness = () => {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: "Bạn có chắc chắn xoá sản phẩm này?",
        okText: "Xác nhận",
        cancelText: "Huỷ",
        onOk: () => removeProduct(),
      });
    };
  
    useEffect(() => {
        fetchCategoryChild();
        fetchAttribute();
        if (Object.keys(initialValues)?.length > 0) {
          form.resetFields();
          setSelectAttribute(initialValues[0]?.attribute);
        }
    }, [form, initialValues]);

    return (
      <div className={"p-[40px] bg-white rounded-[10px]"}>
        <div className={"!text-[#2d2e32] pb-[10px]"}>
          <Link
            to={"/admin/quan-ly-danh-muc-con"}
            className={
              "text-[18px] sm:text-[24px] md:text-[26px] xl:text-[26px] font-[500] cursor-pointer "
            }
          >
            {"Thông tin sản phẩm"}
          </Link>
        </div>
  
        <Form
          layout={"vertical"}
          colon={false}
          form={form}
          initialValues={initialValues?.[0]}
          onFinishFailed={(e) => console.log(e)}
          onFinish={onSubmit}
        >
            <Form.Item
                label={"Tên"}
                name="product_name"
                rules={[{ required: true, message: "Vui lòng nhập tên!" }]}
            >
                <Input size="large" placeholder={"Nhập"} />
            </Form.Item>
            
            {id > 0 && (
              <Form.Item
                  label={"Slug"}
                  name="product_slug"
                  rules={[{ required: true, message: "Vui lòng nhập url!" }]}
              >
                  <Input size="large" placeholder={"Nhập"} />
              </Form.Item>
            )}

            <Form.Item
                label={"Link tiếp thị"}
                name="product_link"
                rules={[{ required: true, message: "Vui lòng nhập link tiếp thị!" }]}
            >
                <Input size="large" placeholder={"Nhập"} />
            </Form.Item>
              
            <Form.Item
                label={"Tiền giới thiệu (%)"}
                name="price_mkt"
                rules={[{ required: false}]}
            >
                <Input type="number" size="large" placeholder={"Nhập"} />
            </Form.Item>
            
            <div className="grid grid-cols-3 gap-4">
              <Form.Item
                  name="product_image"
                  label={"Link ảnh"}
                  rules={[{ required: true, message: "Vui lòng chọn file!" }]}
              >
                  <CustomUpload type="image" accept=".png, .jpg, .jpeg, .jfif" />
              </Form.Item>
      
              <Form.Item
                  name="product_image_slide"
                  label={"Link ảnh slide (tối đa 3 ảnh)"}
                  rules={[{ required: true, message: "Vui lòng chọn file!" }]}
              >
                  <CustomUpload type="image" accept=".png, .jpg, .jpeg, .jfif" multiple />
              </Form.Item>
      
              <Form.Item
                name="product_image_file"
                label={"Nhập link pdf nếu đây là sản phẩm sách (https://drive.google.com/file/d/<id>/preview)"}
              >
                <Input size="large" placeholder={"Nhập"} />
              </Form.Item> 
            </div>

            <div className="grid grid-cols-3 gap-4">
              <Form.Item 
                label={"Danh mục con"} 
                name="category_child_id"
              >
                <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    size="large"
                    placeholder="Select a person"
                    optionFilterProp="children"
                    options={categoryChild?.data?.map((value) => ({
                      value: value.category_child_id,
                      label: value.category_child_name,
                    }))}
                />
              </Form.Item>
              
              <Form.Item
                  label={"Giá tiền"}
                  name="product_price"
                  rules={[{ required: true, message: "Vui lòng nhập giá tiền!" }]}
              >
                  <Input type="number" size="large" placeholder={"Nhập"} />
              </Form.Item>
              
              <Form.Item
                  label={"Giảm giá"}
                  name="product_discount"
                  rules={[{ required: false}]}
              >
                  <Input type="number" size="large" placeholder={"Nhập"} />
              </Form.Item>
            </div>

            <p className="bg-blue-600 w-min px-4 py-1 mb-5 text-white rounded-lg cursor-pointer" onClick={()=> setSelectAttribute([...selectAttribute, []])}>Thêm</p>
            {selectAttribute.map((attrs, attrIndex) => (
              <>
                <div className="grid grid-cols-10 gap-4">
                  <Form.Item className="col-span-9" label={`Thuộc tính ${attrIndex+1}`} name={`attribute_${attrIndex}`}>
                    <Select
                      showSearch
                      mode="multiple"
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      size="large"
                      placeholder="Chọn thuộc tính"
                      optionFilterProp="children"
                      onChange={(e)=>handleChange(e, attrIndex)}
                      options={attribute?.data?.map((value) => ({
                        value: value.attribute_id,
                        label: value.type,
                      }))}
                    />
                  </Form.Item>
                  {(attrIndex + 1) == selectAttribute?.length && (
                    <div className="flex justify-center items-center">
                      <p 
                        className="bg-blue-600 w-min px-6 py-4 mb-5 text-white rounded-lg font-semibold cursor-pointer"
                        onClick={() => {
                          const newSelectAttribute = selectAttribute.filter((_, i) => i !== attrIndex);
                          setSelectAttribute(newSelectAttribute);
                        }}
                      >
                        Xóa
                      </p>
                    </div>
                  )}
                </div>
                
                <div className="grid grid-cols-12 gap-4">
                  {selectAttribute[attrIndex]?.map((_,i) => (
                    <div className="col-span-4 grid grid-cols-3 gap-4 flex items-center px-10">
                      <p className="text-lg">{_?.type}:</p>
                      <Form.Item
                        label={"Tên"}
                        className="col-span-2"
                        name={`attribute_${attrIndex}_${i}`}
                        rules={[{ required: true, message: "Vui lòng nhập tên!" }]}
                      >
                        <Input size="large" placeholder={"Nhập"} />
                      </Form.Item>
                    </div>
                  ))}
                </div>
              </>
            ))}

            <Form.Item
                label={"Đặc điểm nổi bật"}
                name="product_special"
                rules={[{ required: true, message: "Vui lòng nhập đặc điểm nổi bật!" }]}
            >
              <CustomSunEditor value={editorSpec} onChange={(e)=> setEditorSpec(e)} />
            </Form.Item>

            <Form.Item
                label={"Thông tin chi tiết"}
                name="product_information"
                rules={[{ required: true, message: "Vui lòng nhập mô tả sản phẩm!" }]}
            >
              <CustomSunEditor value={editorInfor} onChange={(e)=> setEditorInfor(e)} />
            </Form.Item>

            <Form.Item
                label={"Mô tả"}
                name="product_description"
                rules={[{ required: true, message: "Vui lòng nhập mô tả sản phẩm!" }]}
            >
              <CustomSunEditor value={editorValue} onChange={(e)=> setEditorValue(e)} />
            </Form.Item>
  
            <Row gutter={40} className={"my-[40px] pl-[20px]"}>
                <Space align="center">
                <Button type={"primary"} htmlType={"submit"}>
                    {id && id !== "tao-moi" ? "Cập nhập" : "Tạo"}
                </Button>
                {id && id !== "tao-moi" && (
                    <Button type={"primary"} danger onClick={confirmDeleteBusiness}>
                        Xoá
                    </Button>
                )}
                </Space>
            </Row>
        </Form>
      </div>
    );
  }
  