import { Image } from "antd";
import { useState } from "react";

export default function ImageGallery({product_image}) {
    const [current, setCurrent] = useState(0);

    return (
        <div>
            <div className="flex justify-center py-5">
                <Image src={product_image[current]} width={450} height={450}/>
            </div>
            <div className="flex justify-center pb-5">
                {product_image?.map((_, i) => (
                    <img className={`${i === current ? "border border-blue-500 p-1 rounded-lg" : ""} mx-1`} src={_} width={80} height={80} onMouseOver={()=> setCurrent(i)}/>
                ))}
            </div>
        </div>
    )
}