import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { Col, Menu, Row, message } from "antd";
import { BookOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { getOrderByUser } from "../../api/order";
import { useCookies } from "react-cookie";
import TableOrder from "../../section/tableOrder";
import MyBook from "../../section/myBook";
import { getByUserId } from "../../api/myBook";
import { useDevice } from "../../hooks";

export default function Book() {
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    const [book, setBook] = useState([]);
    const [category_child_id, setCategoryChildId] = useState(0);
    const [paginationBook, setPaginationBook] = useState({
      page: 1,
      pageSize: 5,
    });
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);

    const items = [
        {
          key: 'order',
          label: <a href="/thong-tin-ca-nhan">Đơn hàng của tôi</a>,
          icon: <ShoppingCartOutlined />
        },
        {
          key: 'my-book',
          label: <a href="/sach-cua-toi">Sách của tôi</a>,
          icon: <BookOutlined />
        }
    ];

    useEffect(()=> {        
        const queryBook = `page=${paginationBook?.page}&pageSize=${paginationBook?.pageSize}&category_child_id=${category_child_id}`;
        getByUserId(cookies?.user?.user_id, queryBook, setBook);
    }, [paginationBook, category_child_id])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    useEffect(() => {
        if(!cookies?.user?.user_id) {
            navigate("/dang-nhap");
            message.info("Bạn vui lòng đăng nhập để được dùng chức năng này!")
        }
    }, [cookies]);

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div className="flex pb-4">
                <a href="">Trang chủ</a> 
                <p className="px-2">/</p> 
                <a href="">Thông tin cá nhân</a>
            </div>

            <Row>
                <Col xs={24} xl={4} className="p-1">
                    <Menu
                        className="w-full"
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={items}
                    />
                </Col>
                <Col xs={24} xl={20} className="p-1">
                    <MyBook book={book} pagination={paginationBook} setPagination={setPaginationBook} setCategoryChildId={setCategoryChildId} />
                </Col>
            </Row>
        </div>
    )
}