import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as CONSTANTS from "../../utils/constants";
import { getCookie, setCookie } from "../../utils/localStorageHandle";
import { calculateTotalPrice } from "../../../src/helper"
import { Button, Empty, Image, message } from "antd";
import { FormatVND } from "../../utils/format";
import InputGroup from "../../component/inputGroup";
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../redux/actions/buyProduct";
import { useDevice } from "../../hooks";
import CartIsMobile from "./cartIsMobile";

export default function Cart() {
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cart, setCart] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);

    const onChangeQuantityHandler = (quantity, productID) => {
        if (quantity > 0) {
            if (quantity <= 0) return;
            var foundIndex = cart.findIndex(
                (element) => element.product_id == productID
            );
            cart[foundIndex].quantity = quantity;
            cart[foundIndex].totalprice =
            cart[foundIndex].quantity * cart[foundIndex].price;
            setCookie(CONSTANTS.cartCookie, JSON.stringify(cart));
            setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
            loadTotalPayment();
        }
    };

    const loadTotalPayment = () => {
      setSubtotal(calculateTotalPrice(cart));
    };

    useEffect(() => {
        setTotalPayment(subtotal);
    }, [subtotal]);

    useEffect(() => {
        setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
        if(cart) {
            loadTotalPayment();
        }
    }, [cart]);

    return (
        <section className={`max-w-screen-2xl mx-auto my-10 ${isMobile && "pt-10"}`}>
            <div className='my-1 p-5 bg-white'>
                <h1 className='text-[28px] font-semibold'>Giỏ hàng</h1>
            </div>
            {isMobile ? (
                <CartIsMobile />
            ): (
                <>
                    <div className='border-b border-slate-400 p-5 bg-white'>
                        <div className="grid grid-cols-6 text-center text-lg font-semibold">
                            <p>Số thứ tự</p>
                            <p>Tên sản phẩm</p>
                            <p>Hình ảnh sản phẩm</p>
                            <p>Số lượng</p>
                            <p>Số tiền</p>
                            <p>Thao tác</p>
                        </div>
                    </div>
        
                    {cart ? (
                        <div>
                            <div className='bg-white'>
                                {cart?.map((_,i) => (
                                    <div className="py-5 border-b border-slate-200 grid grid-cols-6 text-center text-lg font-semibold flex items-center">
                                        <p>{i + 1}</p>
                                        <p>{_?.product_name}</p>
                                        <p><Image src={JSON.parse(_?.product_image)?.filter((img) => img.type === 'image')?.[0]?.data} width={200} /></p>
                                        <p className="flex justify-center">
                                            <InputGroup 
                                                count={_?.quantity} 
                                                setCount={(e) => {
                                                    onChangeQuantityHandler(
                                                        e.target.value,
                                                        _?.product_id
                                                    )
                                                }} 
                                                onClickButtonMinus={()=> {
                                                    onChangeQuantityHandler(
                                                        _?.quantity - 1,
                                                        _?.product_id
                                                    )
                                                }}
                                                onClickButtonPlus={() => 
                                                    {(_?.quantity >= _?.characteristics.total) ?
                                                    onChangeQuantityHandler(
                                                        _?.quantity + 1,
                                                        _?.product_id
                                                    ) : (
                                                        message.info("Số lượng bạn chọn lớn hơn số hàng còn lại trong kho!")
                                                    )}
                                                }
                                            />
                                        </p>
                                        <p className="text-[#FF424E] font-semibold">{FormatVND(_?.totalprice)}</p>
                                        <p>
                                            <DeleteOutlined 
                                                className="text-red-500"
                                                onClick={()=> {
                                                    dispatch(deleteProduct(_));
                                                    setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
                                                }}              
                                            />
                                        </p>
                                    </div>
                                ))}
                            </div>
        
                            <div className='my-1 p-5 bg-white'> 
                                <div className="flex justify-between px-20">
                                    <p className="text-lg font-semibold">Tổng tiền tạm tính:</p>
                                    <p className="text-[#FF424E] font-semibold text-lg">{FormatVND(subtotal)}</p>
                                </div>
                            </div>
        
                            <div className="flex justify-between my-5">
                                <button className="border px-4 py-2 bg-white font-semibold hover:bg-gray-50" onClick={()=>navigate("/")}>Tiếp tục mua hàng</button>
                                <button className="border px-4 py-2 bg-blue-500 text-white font-semibold hover:bg-blue-400" onClick={()=>navigate("/thanh-toan")}>Thanh toán</button>
                            </div>
                        </div>
                    ) : (
                        <Empty />
                    )}
                </>
            )}
        </section>
    )
}