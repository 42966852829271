import { Table, message, Button, Row, Col, Pagination, Image } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";

export default function CategoryChildManage() {
  const [categoryChild, setCategoryChild] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const fetchCategoryChild = async () => {
    await axiosInstance.get(`/category-child/getAll`, {params: pagination})
      .then((res) => {
        const data = res?.data;
        setCategoryChild(data);
      })
      .catch(() => message.error("Error server!"));
  };

  useEffect(() => {
    fetchCategoryChild();
  }, [pagination]);

  const columns = [
    {
      title: <div>ID</div>,
      key: "category_child_id",
      dataIndex: "category_child_id",
      width: 100,
      sorter: (a, b) => a.category_child_id - b.category_child_id,
      render: (_, record) => <div>{record?.category_child_id}</div>,
    },
    {
      title: <div>Tên</div>,
      key: "category_child_name",
      dataIndex: "category_child_name",
      width: 150,
      ...SearchProps("category_child_name"),
      render: (_, record) => <div>{record?.category_child_name}</div>,
    },
    {
      title: <div>Ảnh</div>,
      key: "category_child_image",
      dataIndex: "category_child_image",
      width: 150,
      render: (_, record) => <div><Image src={record?.category_child_image} width={100} height={100}/></div>,
    },
    {
      title: <div>Danh mục cha</div>,
      key: "category_name",
      dataIndex: "category_name",
      width: 150,
      ...SearchProps("category_name"),
      render: (_, record) => <div>{record?.category_name}</div>,
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
      key: "create_at",
      dataIndex: "create_at",
      width: 100,
      sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
      render: (_, record) => {
        return (
          <div className={"cursor-pointer text-[14px] font-normal"}>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.create_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày cập nhật</div>,
      key: "update_at",
      dataIndex: "update_at",
      width: 100,
      render: (_, record) => {
        return (
          <div className={"cursor-pointer text-[14px] font-normal"}>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.update_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      key: "operation",
      dataIndex: "operation",
      width: 50,
      render: (_, record) => {
        return (
          <Link
            to={`/admin/quan-ly-danh-muc-con/${record?.category_child_id}`}
            className={"text-[var(--blue)]"}
          >
            <EditOutlined />
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Row gutter={10} className={"mb-[8px]"}>
          <Col flex={1}>
            <div className={"text-[20px] font-medium"}>Danh mục con</div>
          </Col>
          <Col>
            <Link to={"/admin/quan-ly-danh-muc-con/tao-moi"}>
              <Button type={"primary"} onClick={() => {}}>
                Tạo
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={categoryChild?.data}
          columns={columns}
          pagination={false}
        />
        <Pagination
          className="flex justify-center absolute inset-x-0 bottom-10"
          current={pagination.page}
          total={categoryChild?.total}
          pageSize={pagination.pageSize}
          showSizeChanger
          onChange={(p, ps)=> {
            setPagination({
              page: p,
              pageSize: ps
            })
          }}
        />
      </div>
    </>
  );
}
