import axiosInstance from "../utils/axios";

export const getAll = async (query, setData) => {
    try {
        await axiosInstance.get(`/category/getAll?${query}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getAllCategoryIndex = async (setData) => {
    try {
        await axiosInstance.get(`/category-index/getAll`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getAllIndexPage = async (setData) => {
    try {
        await axiosInstance.get(`/category-index/getAllIndexPage`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getRandomIndexPage = async (query, setData) => {
    try {
        await axiosInstance.get(`/category-index/getRandomIndexPage?${query}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getAllByCategoryIndexId = async (id, query, setData) => {
    try {
        await axiosInstance.get(`/category-index/getByCategoryIndexId/${id}?${query}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getBySlug = async (query, setData) => {
    try {
        await axiosInstance.get(`/category/getBySlug/${query}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getByCategoryIndexId = async (query, setData) => {
    try {
        await axiosInstance.get(`/category-index/getById/${query}`)
            .then((res) => {
                setData(res?.data?.data[0]);
            })
    } catch (error) {
        console.log(error);
    }
}

export const updateCategoryIndex = async (query, category_index_id, message, fetchProduct) => {
    try {
        await axiosInstance.post(`/category-index/updateCategoryIndex/${query}`, {category_index_id: category_index_id})
            .then((res) => {
                message.success(String(res?.data?.message));
                fetchProduct();
            })
    } catch (error) {
        console.log(error);
    }
}

export const updatePinned = async (query, category_index_id, pinned, message, fetchProduct) => {
    try {
        await axiosInstance.post(`/category-index/updatePinned/${query}`, {category_index_id: category_index_id, pinned: pinned})
            .then((res) => {
                message.success(String(res?.data?.message));
                fetchProduct();
            })
    } catch (error) {
        console.log(error);
    }
}
