export const cartCookie = "phan_nguyen_ecommerce_cart";
export const emailCookie = "phan_nguyen_ecommerce_email";
export const voucherCookie = "phan_nguyen_ecommerce_voucher";

export const orderStatus = [
  { id: 0, data: "Chưa xác nhận", color: "font-semibold text-black" },
  { id: 1, data: "Giao Hàng Thành Công", color: "font-semibold text-green-600" },
  { id: 2, data: "Đang gửi hàng", color: "font-semibold text-yellow-600" },
  { id: 3, data: "Đã hủy đơn", color: "font-semibold text-red-600" },
];

export const payment = [
  { value: 1, label: "Thanh toán khi nhận hàng" },
  { value: 2, label: "Thanh toán bằng ngân hàng"},
  { value: 3, label: "Thanh toán bằng momo" },
];

export const productSliderSettings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
};

export const properties = {
  prevArrow: <button className="bg-white shadow-lg rounded-full p-2 opacity-60"><svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.0899 14.5899C11.7645 14.9153 11.2368 14.9153 10.9114 14.5899L5.91139 9.58991C5.58596 9.26447 5.58596 8.73683 5.91139 8.4114L10.9114 3.41139C11.2368 3.08596 11.7645 3.08596 12.0899 3.41139C12.4153 3.73683 12.4153 4.26447 12.0899 4.58991L7.67916 9.00065L12.0899 13.4114C12.4153 13.7368 12.4153 14.2645 12.0899 14.5899Z" fill="#0A68FF"></path></svg></button>,
  nextArrow: <button className="bg-white shadow-lg rounded-full p-2 opacity-60"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.91107 3.41107C6.23651 3.08563 6.76414 3.08563 7.08958 3.41107L12.0896 8.41107C12.415 8.73651 12.415 9.26415 12.0896 9.58958L7.08958 14.5896C6.76414 14.915 6.23651 14.915 5.91107 14.5896C5.58563 14.2641 5.58563 13.7365 5.91107 13.4111L10.3218 9.00033L5.91107 4.58958C5.58563 4.26414 5.58563 3.73651 5.91107 3.41107Z" fill="#0A68FF"></path></svg></button>
}
