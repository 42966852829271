import React, { useEffect, useState } from 'react';
import { useDevice } from '../hooks';

const CountdownTimer = () => {
    const { isMobile } = useDevice();
    const [timeLeft, setTimeLeft] = useState(4 * 60 * 60); 

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft(prev => (prev > 0 ? prev - 1 : clearInterval(intervalId)));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return { hours, minutes, secs };
    };

    const { hours, minutes, secs } = formatTime(timeLeft);

    return (
        <div className={`font-semibold flex justify-center items-center text-center ${isMobile ? 'text-[13px]' : 'text-lg'} pl-2`}>
            <div className='text-white rounded-md px-2 py-1' style={{ backgroundColor: '#ff424e' }}>{String(hours).padStart(2, '0')}</div> 
            <div className='px-1 text-gray-600'>:</div>
            <div className='text-white rounded-md px-2 py-1' style={{ backgroundColor: '#ff424e' }}>{String(minutes).padStart(2, '0')}</div>
            <div className='px-1 text-gray-600'>:</div>
            <div className='text-white rounded-md px-2 py-1' style={{ backgroundColor: '#ff424e' }}>{String(secs).padStart(2, '0')}</div>
        </div>
    );
};

export default CountdownTimer;