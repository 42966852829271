import { Table, message, Button, Row, Col, Pagination, Image, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";
import { FormatVND } from "../../../utils/format";
import { getAllCategoryIndex, updateCategoryIndex, updatePinned } from "../../../api/category";

export default function ProductManage() {
  const [product, setProduct] = useState([]);
  const [categoryIndex, setCategoryIndex] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const fetchProduct = async () => {
    await axiosInstance.get(`/product/getAll`, {params: pagination})
      .then((res) => {
        const data = res?.data;
        setProduct(data);
      })
      .catch(() => message.error("Error server!"));
  };

  const columns = [
    {
      title: <div>ID</div>,
      key: "product_id",
      dataIndex: "product_id",
      width: 100,
      sorter: (a, b) => a.product_id - b.product_id,
      render: (_, record) => <div>{record?.product_id}</div>,
    },
    {
      title: <div>Tên</div>,
      key: "product_name",
      dataIndex: "product_name",
      width: 150,
      ...SearchProps("product_name"),
      render: (_, record) => <div>{record?.product_name}</div>,
    },
    {
      title: <div>Giá tiền</div>,
      key: "product_price",
      dataIndex: "product_price",
      width: 150,
      render: (_, record) => <div>{FormatVND(record?.product_price)}</div>,
    },
    {
      title: <div>Ảnh</div>,
      key: "product_image",
      dataIndex: "product_image",
      width: 150,
      render: (_, record) => <div><Image src={JSON.parse(record?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data} width={100} height={100}/></div>,
    },
    {
      title: <div>Danh mục con</div>,
      key: "category_child_name",
      dataIndex: "category_child_name",
      width: 150,
      ...SearchProps("category_child_name"),
      render: (_, record) => <div>{record?.category_child_name}</div>,
    },
    {
      title: <div>Danh mục trang chủ</div>,
      key: "category_index_id",
      dataIndex: "category_index_id",
      width: 150,
      sorter: (a, b) => a.category_index_id - b.category_index_id,
      render: (_, record) => 
        <div>
          <Select
            options={[
              ...categoryIndex?.data?.map(item => ({
                value: item?.category_index_id,
                label: (
                  <div className="flex items-center">
                    <img src={item?.category_index_image} className="w-full h-10" alt={item?.category_index_id} />
                  </div>
                )
              })) || [],
              {
                value: null,
                label: (
                  <div className="flex items-center">
                    <span>None</span>
                  </div>
                )
              }
            ]}
            className="w-[100px]"
            value={record?.category_index_id}
            defaultValue={record?.category_index_id}
            onChange={(selectedOption) => {
              updateCategoryIndex(record?.product_id, selectedOption, message, fetchProduct);
            }}
          />
        </div>,
    },
    {
      title: <div>Ghim</div>,
      key: "pinned",
      dataIndex: "pinned",
      width: 150,
      render: (_, record) => 
        <div>
          <Select
            options={[
              ...Array.from({ length: 15 }, (_, index) => ({
                value: index + 1,
                label: (
                  <div className="flex items-center">
                    <span>{index + 1}</span>
                  </div>
                )
              })),
              {
                value: null,
                label: (
                  <div className="flex items-center">
                    <span>None</span>
                  </div>
                )
              }
            ]}
            className="w-[100px]"
            disabled={record?.category_index_id ? false : true}
            value={record?.category_index_id ? record?.pinned : "None"}
            onChange={(selectedOption) => {
              if(record?.category_index_id) {
                updatePinned(record?.product_id, record?.category_index_id, selectedOption, message, fetchProduct);
              } else {
                message.info("Bạn vui lòng chọn danh mục trang chủ trước khi chọn ghim!")
              }
            }}
          />
        </div>,
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
      key: "create_at",
      dataIndex: "create_at",
      width: 100,
      sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
      render: (_, record) => {
        return (
          <div className={"cursor-pointer text-[14px] font-normal"}>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.create_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày cập nhật</div>,
      key: "update_at",
      dataIndex: "update_at",
      width: 100,
      render: (_, record) => {
        return (
          <div className={"cursor-pointer text-[14px] font-normal"}>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.update_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      key: "operation",
      dataIndex: "operation",
      width: 50,
      render: (_, record) => {
        return (
          <Link
            to={`/admin/quan-ly-san-pham/${record?.product_id}`}
            className={"text-[var(--blue)]"}
          >
            <EditOutlined />
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    fetchProduct();
    getAllCategoryIndex(setCategoryIndex);
  }, [pagination]);

  return (
    <>
      <div>
        <Row gutter={10} className={"mb-[8px]"}>
          <Col flex={1}>
            <div className={"text-[20px] font-medium"}>Quản lý sản phẩm</div>
          </Col>
          <Col>
            <Link to={"/admin/quan-ly-san-pham/tao-moi"}>
              <Button type={"primary"} onClick={() => {}}>
                Tạo
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={product?.data}
          columns={columns}
          pagination={false}
        />
        <Pagination
          className="flex justify-center absolute inset-x-0 bottom-10"
          current={pagination.page}
          total={product?.total}
          pageSize={pagination.pageSize}
          showSizeChanger
          onChange={(p, ps)=> {
            setPagination({
              page: p,
              pageSize: ps
            })
          }}
        />
      </div>
    </>
  );
}
