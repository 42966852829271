import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ItemProduct from '../../section/itemProduct';
import {dataProduct} from '../../utils/dataFake'
import { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../utils/axios';
import { Col, Empty, Row, message } from 'antd';
import { getAll, getAllIndexPage, getRandomIndexPage } from '../../api/category';
import { properties } from '../../utils/constants';
import ItemProductGrid from '../../section/itemProductGrid';
import CountdownTimer from '../../component/countdownTimer';
import { useDevice } from "../../hooks";
import HomeIsMobile from './homeIsMobile';
import AutoCompleteCustom from '../../component/autoCompleteCustom';

export default function Home() {
    const { isMobile } = useDevice();
    const [category, setCategory] = useState([]);
    const [index, setIndex] = useState([]);
    const [categoryIndex, setCategoryIndex] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const [randomIndex, setRandomIndex] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(10);
    
    const fetchIndexPage = async () => {
        await axiosInstance.get(`/index-page/getAllIndexPage`)
        .then((res) => {
            const data = res?.data;
            setIndex(data);
        })
        .catch(() => message.error("Error server!"));
    };

    const handleLoadMore = () => {
        setItemsToShow(prev => prev + 10);
    };
    
    useEffect(() => {
        let query = `page=1&pageSize=${itemsToShow}`;
        getRandomIndexPage(query, setRandomIndex);
        getAll("page=1&pageSize=100", setCategory);
        getAllIndexPage(setCategoryIndex);
        fetchIndexPage();
    }, [itemsToShow]);
    
    useEffect(() => {
        const handleScroll = () => {
            setIsFixed(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="max-w-screen-2xl mx-auto">
            {isMobile ? <HomeIsMobile /> : (
                <Row>
                    <Col xs={24} xl={4} className='pr-2'>
                        <div 
                            className={`my-2 p-5 bg-white rounded-lg h-[100vh] ${isFixed ? "fixed top-0" : "overflow-y-visible"}`} 
                            style={{
                                scrollbarWidth: 'none'
                            }}
                        >
                            {category?.data?.map((_,i)=> (
                                <a href={`/danh-muc-san-pham/${_?.category_slug}`} className='flex items-center p-2 hover:bg-gray-200 rounded-xl'>
                                    <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                    <p className='pl-2 text-black w-[150px]'>{_?.category_name}</p>
                                </a>
                            ))}
                        </div>
                    </Col>
                    <Col xs={24} xl={20}>
                        <div className='my-2 p-5 bg-white rounded-lg'>
                            {index?.data?.length > 0 ? (
                                <Slide cssClass='rounded-xl' {...properties}>
                                    {index?.data?.map((_,i) => (
                                        <div className="each-slide-effect flex justify-center h-[400px]">
                                            <a href={_?.index_page_link}>
                                                <img 
                                                    src={_?.index_page_image}
                                                    className='w-full h-full'
                                                />
                                            </a>
                                        </div>
                                    ))}
                                </Slide>
                            ): (
                                <Empty />
                            )}
                        </div>

                        <div className='p-5 bg-white rounded-xl'>
                            <div className='grid grid-cols-10 gap-4'>
                                {category?.data?.map((_,i)=> (
                                    <a href={`/danh-muc-san-pham/${_?.category_slug}`}>
                                        <div className='flex justify-center'>
                                            <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                        </div>
                                        <p className='text-center pt-1 text-black'>{_?.category_name}</p>
                                    </a>
                                ))}
                            </div>
                        </div>
                        
                        {categoryIndex?.data?.map((_,i) => (
                            <div className='my-2 p-5 bg-white rounded-xl'>
                                <div className='flex justify-between'>
                                    <div className='flex items-center'>
                                        <img src={_?.category_index_image} className='h-12'/>
                                        {_?.category_index_id == 2 && (
                                            <CountdownTimer />
                                        )}
                                    </div>
                                    <a href={`/danh-muc-trang-chu/${_?.category_index_id}`}>
                                        <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                                    </a>
                                </div>
                                <div className='py-8'>
                                    <ItemProduct product={_?.product} grid={5} titleSize={13} heightTitle={10}/>
                                </div>
                            </div>
                        ))}

                        <div className='my-2 p-5 bg-white rounded-xl'>
                            <div className='flex justify-between'>
                                <p className='font-bold text-2xl'>Gợi ý hôm nay</p>
                                <a href='/danh-muc-trang-chu/0'>
                                    <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                                </a>
                            </div>
                            <div className='py-8'>
                                <ItemProductGrid product={randomIndex?.data} grid={5} titleSize={13} heightTitle={10}/>
                            </div>
                            <div className='flex justify-center'>
                                <button className='border px-4 py-2 font-semibold text-lg text-blue-600 border-blue-600 hover:bg-blue-100' onClick={handleLoadMore}>Xem thêm</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    )
}