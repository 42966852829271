import React, { useEffect, useState } from 'react';
import { AutoComplete, Input, message } from 'antd';
import axiosInstance from '../../utils/axios';
import { fetchSearchPage } from '../../api/searchPage';
import { useDevice } from '../../hooks';
import { useNavigate } from 'react-router-dom';

const AutoCompleteCustom = () => {
  const { isMobile } = useDevice();
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");

  const fetchSuggestions = (query) => {
    let querySearch = `keyword=${query}&page=1&pageSize=100`
    fetchSearchPage(querySearch, setData);
    
    return data?.data?.map((item) => ({
      value: item.product_name,
      label: (
        <a href={`/san-pham/${item?.product_slug}`}>
          <div className='flex items-center text-black'>
            <img src={JSON.parse(item?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data} className={`${isMobile ? "w-[40px] h-[40px]" : "w-[50px] h-[50px]"} rounded-lg`}/>
            <p className='font-semibold pl-2'>{item?.product_name}</p>
          </div>
        </a>
      ),
    }));
  };

  const handleSearch = async (value) => {
    if (!value) {
      setOptions([]);
      return;
    }

    const suggestions = await fetchSuggestions(value);
    setOptions(suggestions);
  };

  const onSelect = (value) => {
    console.log('onSelect', value);
  };

  useEffect(()=>{
    fetchSuggestions(keyword);
  }, [keyword])

  return (
    <AutoComplete
      popupMatchSelectWidth={isMobile ? 300 : 700}
      className='w-full'
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch}
      size="large"
    >
      <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <Input.Search 
          size="large" 
          placeholder="Tìm kiếm sản phẩm" 
          onChange={(e)=>setKeyword(e?.target?.value)} onSearch={()=>{
            if(keyword){
              navigate(`/tim-kiem/${keyword}`); 
              window.location.reload();
            } else {
              message.info("Vui lòng nhập từ khóa bạn muốn tìm kiếm!");
            }}
          } 
          enterButton 
        />
        
      </div>
    </AutoComplete>
  );
};

export default AutoCompleteCustom;