import { Table, message, Button, Row, Col, Pagination, Image } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";

export default function IndexManage() {
  const [index, setIndex] = useState([]);

  const fetchIndexPage = async () => {
    await axiosInstance.get(`/index-page/getAllIndexPage`)
      .then((res) => {
        const data = res?.data;
        setIndex(data);
      })
      .catch(() => message.error("Error server!"));
  };

  useEffect(() => {
    fetchIndexPage();
  }, []);

  const columns = [
    {
      title: <div>ID</div>,
      key: "index_page_id",
      dataIndex: "index_page_id",
      width: 100,
      sorter: (a, b) => a.index_page_id - b.index_page_id,
      render: (_, record) => <div>{record?.index_page_id}</div>,
    },
    {
      title: <div>Ảnh</div>,
      key: "index_page_image",
      dataIndex: "index_page_image",
      width: 150,
      render: (_, record) => <div><Image src={record?.index_page_image} height={100}/></div>,
    },
    {
      title: <div>Link</div>,
      key: "index_page_link",
      dataIndex: "index_page_link",
      width: 150,
      render: (_, record) => <div>{record?.index_page_link}</div>,
    },
    {
      key: "operation",
      dataIndex: "operation",
      width: 50,
      render: (_, record) => {
        return (
          <Link
            to={`/admin/quan-ly-trang-chu/${record?.index_page_id}`}
            className={"text-[var(--blue)]"}
          >
            <EditOutlined />
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Row gutter={10} className={"mb-[8px]"}>
          <Col flex={1}>
            <div className={"text-[20px] font-medium"}>Quản lý trang chủ</div>
          </Col>
          <Col>
            <Link to={"/admin/quan-ly-trang-chu/tao-moi"}>
              <Button type={"primary"} onClick={() => {}}>
                Tạo
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={index?.data}
          columns={columns}
          pagination={false}
        />
      </div>
    </>
  );
}
