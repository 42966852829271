import {
    Form,
    Row,
    Input,
    Space,
    Button,
    message,
    Modal,
  } from "antd";
  import { useEffect, useState } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import { ExclamationCircleOutlined } from "@ant-design/icons";
  import CustomUpload from "../customUpload";
import axiosInstance from "../../utils/axios";
  
  export default function IndexPageForm({
    id = "",
    initialValues = {},
    onSubmit = () => {},
  }) {
    const navigate = useNavigate();
    const [form] = Form.useForm();
  
    const deleteIndexPage = async () => {
      await axiosInstance.delete(`/index-page/deleteIndexPage/${id}`)
        .then((res) => message.success(String(res?.data?.message)))
    };
  
    useEffect(() => {
      if (Object.keys(initialValues)?.length > 0) {
        form.resetFields();
      }
    }, [form, initialValues]);
  
    const removeIndexPage = async () => {
      try {
        await deleteIndexPage();
        return navigate("/admin/quan-ly-trang-chu");
      } catch (err) {
        console.log(err.message);
      }
    };
  
    const confirmDeleteBusiness = () => {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: "Bạn có chắc chắn xoá banner này?",
        okText: "Xác nhận",
        cancelText: "Huỷ",
        onOk: () => removeIndexPage(),
      });
    };
    return (
        <div className={"p-[40px] bg-white rounded-[10px]"}>
            <div className={"!text-[#2d2e32] pb-[10px]"}>
                <Link
                    to={"/admin/quan-ly-trang-chu"}
                    className={
                    "text-[18px] sm:text-[24px] md:text-[26px] xl:text-[26px] font-[500] cursor-pointer "
                    }
                >
                    {"Thông tin banner trang chủ"}
                </Link>
            </div>
    
            <Form
                layout={"vertical"}
                colon={false}
                form={form}
                initialValues={initialValues}
                onFinishFailed={(e) => console.log(e)}
                onFinish={onSubmit}
            >
                <Form.Item
                    name="index_page_image"
                    label={"Link ảnh"}
                    rules={[{ required: true, message: "Vui lòng chọn Banner!" }]}
                >
                    <CustomUpload type="image" accept=".png, .jpg, .jpeg, .jfif" />
                </Form.Item>

                <Form.Item
                    label={"Link ảnh"}
                    name="index_page_link"
                    rules={[{ required: true, message: "Vui lòng nhập link!" }]}
                >
                    <Input size="large" placeholder={"Nhập"} />
                </Form.Item>
        
                <Row gutter={40} className={"my-[40px] pl-[20px]"}>
                    <Space align="center">
                    <Button type={"primary"} htmlType={"submit"}>
                        {id && id !== "tao-moi" ? "Cập nhập" : "Tạo"}
                    </Button>
                    {id && id !== "tao-moi" && (
                        <Button type={"primary"} danger onClick={confirmDeleteBusiness}>
                            Xoá
                        </Button>
                    )}
                    </Space>
                </Row>
            </Form>
        </div>
    );
  }
  