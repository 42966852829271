import { Form, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axios";
import { getByCategoryIndexId } from "../../../api/category";
import CategoryIndexForm from "../../../component/categoryIndexForm";

export default function CategoryIndexDetail() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const params = useParams();
  const id = params?.id;

  const onSubmit = async (values) => {
    try {
      if (id && id !== "tao-moi") {
        await axiosInstance.post(`/category-index/update/${id}`, values)
          .then((res) => {
            message.success(String(res?.data?.message));
          });
      } else {
        await axiosInstance.post(`/category-index/create`, values)
          .then((res) => {
            message.success(String(res?.data?.message));
          });
      }
      navigate("/admin/quan-ly-danh-muc-trang-chu");
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    if (id && id !== "tao-moi") getByCategoryIndexId(params?.id, setInitialValues);
    form.resetFields();
  }, [form, id]);

  return (
    <CategoryIndexForm
      id={id !== "create" ? id : undefined}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
}
