import google from "../assets/image/google.png";
import axios from 'axios';
import axiosInstance from '../utils/axios';
import { Form, Input, message } from 'antd';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useEffect } from "react";

export default function Register() {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);

    const onSubmit = async (data) => {
        if(data.password === data.password_confirm){
            await axiosInstance.post('/user/register', data)
                .then(async (res) => {
                    message.success(String(res?.data?.message));
                    if(res?.data?.status === 2) {
                        setCookie("user", res?.data?.data[0]);
                        message.success("Đăng nhập thành công!");
                        navigate("/");
                    } 
                })
                .catch((error)=>{
                    message.error(error);
                })
        }else {
            message.warning("Mật khẩu bạn nhập không khớp!");
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 
    
    return (
        <section className="bg-gray-50 dark:bg-gray-900 py-20">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                <a href="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo" />
                    Flowbite    
                </a>
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Đăng ký
                        </h1>
                        <Form
                            layout={"vertical"}
                            colon={false}
                            form={form}
                            onFinishFailed={(e) => console.log(e)}
                            onFinish={onSubmit}
                        >
                            <Form.Item
                                label={<label for="email" className="block text-sm font-medium text-gray-900 dark:text-white">Email</label>}
                                name="email"
                                rules={[{ required: true, message: "Vui lòng nhập Email!" }]}
                            >
                                <input 
                                    type="email" 
                                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                    placeholder="name@company.com" 
                                    required="" />
                            </Form.Item>

                            <Form.Item
                                label={<label for="password" className="block text-sm font-medium text-gray-900 dark:text-white">Mật Khẩu</label>}
                                name="password"
                                rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
                            >
                                <input 
                                    type="password" 
                                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                    placeholder="**************" 
                                    required="" />
                            </Form.Item>

                            <Form.Item
                                label={<label for="password-confirm" className="block text-sm font-medium text-gray-900 dark:text-white">Xác nhận mật khẩu</label>}
                                name="password_confirm"
                                rules={[{ required: true, message: "Vui lòng nhập xác nhận mật khẩu!" }]}
                            >
                                <input 
                                    type="password" 
                                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                    placeholder="**************" 
                                    required="" />
                            </Form.Item>

                            <button 
                                type="submit" 
                                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-lg px-5 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            >
                                Đăng ký
                            </button>
                        </Form>
                    </div>
                </div>
            </div>
        </section>
    )
}