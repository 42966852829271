import AdminRouter from "../../route/admin.route";
import React, { useEffect } from "react";
import { Layout, theme } from "antd";
import CustomeSider from "../customSider";
import { StyleProvider } from '@ant-design/cssinjs'
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axiosInstance from "../../utils/axios";

const { Content } = Layout;
export default function DefaultLayoutAdmin() {
    const {
        token: { colorBgContainer, borderRadiusLG },
      } = theme.useToken();

    return (
        <StyleProvider hashPriority="high">
            <Layout className={"relative "} style={{ minHeight: "100vh" }}>
                <CustomeSider />
                <Layout className="site-layout">
                    <Content
                        className={"relative !-z-0 mt-[10px] p-[20px]"}
                        style={{
                            margin: "24px 16px",
                            padding: 24,
                            minHeight: 280,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <AdminRouter />
                    </Content>
                </Layout>
            </Layout>
        </StyleProvider>
    )
}