import axiosInstance from "../utils/axios";

export const getByUserId = async (id, query, setData) => {
    try {
        await axiosInstance.get(`/my-book/getByUserId/${id}?${query}`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}

export const getBySlug = async (slug, query, setData, message) => {
    try {
        await axiosInstance.get(`/my-book/getBySlug/${slug}?${query}`)
            .then((res) => {
                setData(res?.data);
                message.info(String(res?.data?.message));
            })
    } catch (error) {
        console.log(error);
    }
}

export const getListCategoryChild = async (setData) => {
    try {
        await axiosInstance.get(`/my-book/getListCategoryChild`)
            .then((res) => {
                setData(res?.data);
            })
    } catch (error) {
        console.log(error);
    }
}