import { useEffect, useState } from "react";
import PdfView from "../../component/pdfView";
import { useNavigate, useParams } from "react-router-dom";
import { getBySlug } from "../../api/myBook";
import { useCookies } from "react-cookie";
import { Empty, message } from "antd";
import { useDevice } from "../../hooks";
import { ArrowLeftOutlined } from "@ant-design/icons";

export default function BookDetail() {
    const param = useParams();
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    const [book, setBook] = useState([]);
    const [cookies] = useCookies(["user"]);

    useEffect(() => {
        const query = `user_id=${cookies?.user?.user_id}`;
        getBySlug(param?.slug, query, setBook, message);
    }, []);

    return (
        <section className={`max-w-screen-2xl mx-auto ${isMobile ? "" : "my-5"}`}>
            {/* <div className='flex items-center my-2 p-2 bg-white'>
                {isMobile && (
                    <ArrowLeftOutlined 
                        className="p-2 text-white bg-blue-600 rounded-full" 
                        onClick={() => navigate(-1)} 
                    /> 
                )}
                <p className={`${isMobile ? "text-[16px]" : "text-[28px]"} font-semibold pl-2`}>Sách của tôi/ {param?.slug}</p>
            </div> */}
            {book?.data?.length > 0 ? (
                <PdfView url={JSON.parse(book?.data?.[0]?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data}/>
            ) : (<Empty />)}
        </section>
    )
}