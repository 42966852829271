import { Empty } from "antd";
import { useNavigate } from "react-router-dom"
import { useDevice } from "../../hooks";


export default function ItemCategory({category}) {
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    
    return (
        <div className={`grid ${isMobile ? "grid-cols-5" : "grid-cols-9"} gap-4`}>
            {category?.dataChild?.length > 0 ? category?.dataChild?.map((_, i) => (
                <a onClick={()=>navigate(`/danh-muc-con/${_?.category_child_slug}`)} className="cursor-pointer">
                    <div className="">
                        <div className="flex justify-center items-center">
                            <img src={_?.category_child_image} className={`${isMobile ? "w-[50px] h-[50px]" : "w-[100px] h-[100px]"} border rounded-full bg-gray-50`}/>
                        </div>
                        <div className="py-2">
                            <h3 className={`${isMobile ? "text-[13px]" : "text-[16px]"} font-semibold text-center h-12 text-slate-800`}>{_?.category_child_name}</h3>
                        </div>
                    </div>
                </a>
            )) : (
                <div className={`col-span-6`}>
                    <Empty />
                </div>
            )}
        </div>
    )
}