import { Table, Pagination, Image, Empty, Select} from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../component/SearchProps";
import dayjs from "dayjs";
import { FormatVND } from "../../utils/format";
import { useDevice } from "../../hooks";
import { getListCategoryChild } from "../../api/myBook";


export default function MyBook({book, pagination, setPagination, setCategoryChildId}) {
    const { isMobile } = useDevice();
    const [bookCategory, setBookCategory] = useState([]);

    useEffect(() => {
        getListCategoryChild(setBookCategory);
    }, [])

    return (
        <div className="bg-white p-5">
            <h2 className="text-center font-semibold text-xl">Sách của tôi</h2>
            <div className="flex justify-center">
                <Select
                    showSearch
                    className="w-[200px]"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    size="large"
                    placeholder="Chọn danh mục sách"
                    optionFilterProp="children"
                    onChange={(e)=>setCategoryChildId(e)}
                    options={bookCategory?.map((value) => ({
                        value: value.category_child_id,
                        label: value.category_child_name,
                    }))}
                />
            </div>
            
            <div className={`grid ${isMobile ? "grid-cols-3 gap-2" : "grid-cols-5 gap-4"} w-full h-full mt-5 pb-20 relative`}>
                {book?.data?.length > 0 ? book?.data?.map((_, i)=> (
                    <a href={`/sach-cua-toi/${_?.product_slug}`} >
                        <div className="border rounded-lg">
                            <div className="flex justify-center items-center">
                                <img src={JSON.parse(_?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data} className={`w-full ${isMobile ? "h-[120px]" : "h-[230px]"} rounded-t-lg`} />
                            </div>
                            <div className="p-2">
                                <h3 className={`${isMobile ? "line-clamp-2 text-[12px] h-10" : "line-clamp-2 text-[18px] h-15"}`}>{_?.product_name}</h3>
                                <div className={`${isMobile ? "" : "py-2"}`}>
                                    {_?.product_discount ? (
                                        <>
                                            <p className="text-[#FF424E] font-semibold text-[16px]">{FormatVND(_?.product_price - (_?.product_price * (_?.product_discount/100)))}</p>
                                            <div className="flex items-center">
                                                <p className="bg-[#f5f5fa] p-1 rounded-lg text-[12px] font-semibold">-{_?.product_discount}%</p>
                                                <p className="text-[#808089] text-[10px] pl-2 line-through">{FormatVND(_?.product_price)}</p>
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            <p className="text-[#FF424E] font-semibold text-[16px]">{FormatVND(_?.product_price)}</p>
                                            <div className="flex items-center">
                                                <p className="bg-[#f5f5fa] p-1 rounded-lg text-[12px] font-semibold">-{_?.product_discount}%</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="flex border-t pt-2 mt-2">
                                    <div className="flex items-center">
                                        <img src="https://salt.tikicdn.com/ts/tka/a8/31/b6/802e2c99dcce64c67aa2648edb15dd25.png" className={isMobile ? "h-3" : "h-5"}/>
                                    </div>
                                    <p className="pl-2">{_?.product_time}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                )) : (<Empty />)}
                <Pagination
                    className="flex justify-center absolute inset-x-0 bottom-0"
                    current={pagination.page}
                    total={book?.total}
                    pageSize={pagination.pageSize}
                    showSizeChanger
                    onChange={(p, ps)=> {
                        setPagination({
                            page: p,
                            pageSize: ps
                        })
                    }}
                />
            </div>
        </div>
    )
}