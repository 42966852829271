import { useLocation, useParams } from "react-router-dom"
import ImageGallery from "../../component/imageGallery";
import ProductInfor from "../../section/productInfor";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { message } from "antd";
import parse from 'html-react-parser';
import { getAll, getAllIndexPage } from "../../api/category";
import ItemProduct from "../../section/itemProduct";
import { useDevice } from "../../hooks";
import ProductIsMobile from "./productIsMobile";
import freeship from "../../assets/image/freeship.png";
import { useCookies } from "react-cookie";

export default function Product() {
    const param = useParams();
    const { isMobile } = useDevice();
    const [product, setProduct] = useState([]);
    const [productImage, setProductImage] = useState([]);
    const [isFile, setIsFile] = useState(false);
    const [categoryIndex, setCategoryIndex] = useState([]);
    const [read, setRead] = useState(false);
    const [category, setCategory] = useState([]);
    
    const fetchProduct = async () => {
        await axiosInstance.get(`/product/getBySlug/${param?.slug}`)
        .then((res) => {
            const data = res?.data?.data;
            setProduct(data);
            const image = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data;
            const slide = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'slide')?.[0]?.data;
            if(JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data) { setIsFile(true) }
            setProductImage([image, ...slide]);
        })
        .catch(() => message.error("Error server!"));
    };

    useEffect(() => {
        fetchProduct();
        getAllIndexPage(setCategoryIndex);
    }, [isFile]);

    useEffect(() => {
        getAllIndexPage(setCategoryIndex);
        getAll("page=1&pageSize=100", setCategory);
    }, []);

    return (
        <div className="max-w-screen-2xl mx-auto">
            {isMobile ? <ProductIsMobile /> : (
                <>
                    <div className="flex pt-5">
                        <a href="/">Trang chủ</a> 
                        <p className="px-2">/</p> 
                        <a href={`/danh-muc-san-pham/${product[0]?.category_slug}`}>Danh mục sản phẩm</a>
                        <p className="px-2">/</p> 
                        <a href="">{product[0]?.product_name}</a>
                    </div>
        
                    <div className="grid grid-cols-3 pt-4 gap-2">
                        <div className="bg-white rounded-lg">
                            <ImageGallery product_image={productImage} />
                            <div className="bg-white p-5 ml-2 rounded-lg mt-2">
                                <p className="text-[16px] font-semibold">Đặc điểm nổi bật</p>
                                {parse(String(product[0]?.product_special?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                            </div>
                        </div>
                        <div className="col-span-2 bg-white rounded-lg">
                            <ProductInfor product={product[0]} attribute={product?.attribute} isFile={isFile}/>
                            
                            <div className="p-5 ml-2">
                                <p className="text-[16px] font-semibold">Thông tin chi tiết</p>
                                {parse(String(product[0]?.product_information?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                            </div>
                        </div>

                        <div className="bg-white p-5 rounded-lg col-span-3">
                            <div>
                                <p className="text-[16px] font-semibold">Chính sách trả hàng</p>
                                <p className="pt-1">Xem ở mục mô tả sản phẩm</p>
                            </div>
                            <div className="py-2">
                                <p className="text-[16px] font-semibold">Vận chuyển</p>
                                <div className="flex items-center pt-1">
                                    <div className="flex items-center">
                                        <img src={freeship} width={30} height={10}/>
                                    </div>
                                    <p className=" pl-2">Vào mục mã giảm giá để lấy mã freeship</p>
                                </div>
                            </div>
                        </div>
                            
                        <div className="bg-white p-5 rounded-lg col-span-3">
                            <p className="text-[16px] font-semibold">Mô tả sản phẩm</p>
                            {read ? (
                                <div>
                                    {parse(String(product[0]?.product_description?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                                </div>
                            ) : (
                                <div className="truncate h-[100px]">
                                    {parse(String(product[0]?.product_description?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                                </div>
                            )}
                            <div className="flex justify-center pt-5">
                                <button className="border border-blue-600 px-4 py-2 text-lg font-semibold text-blue-600" onClick={()=> setRead(!read)}>
                                    {!read ? "Xem thêm" : "Rút gọn"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='my-2 p-5 bg-white rounded-xl'>
                        <div className='flex justify-between'>
                            <div className='flex items-center'>
                                <img src={categoryIndex?.data?.[0]?.category_index_image} className='h-12'/>
                            </div>
                            <a href={`/danh-muc-trang-chu/${categoryIndex?.data?.[0]?.category_index_id}`}>
                                <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                            </a>
                        </div>
                        <div className='py-8'>
                            <ItemProduct product={categoryIndex?.data?.[0]?.product} grid={6} titleSize={13} heightTitle={12}/>
                        </div>
                    </div>
        
                    <div className='p-5 bg-white rounded-xl mb-2'>
                        <div className='grid grid-cols-10 gap-4'>
                            {category?.data?.map((_,i)=> (
                                <a href={`/danh-muc-san-pham/${_?.category_slug}`}>
                                    <div className='flex justify-center'>
                                        <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                    </div>
                                    <p className='text-center pt-1 text-black'>{_?.category_name}</p>
                                </a>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}