import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as CONSTANTS from "../../utils/constants";
import { getCookie, setCookie } from "../../utils/localStorageHandle";
import { calculateTotalPrice } from "../../../src/helper"
import { Button, Empty, Image, message } from "antd";
import { FormatVND } from "../../utils/format";
import InputGroup from "../../component/inputGroup";
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../redux/actions/buyProduct";

export default function CartIsMobile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cart, setCart] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);

    const onChangeQuantityHandler = (quantity, productID) => {
        if (quantity > 0) {
            if (quantity <= 0) return;
            var foundIndex = cart.findIndex(
                (element) => element.product_id == productID
            );
            cart[foundIndex].quantity = quantity;
            cart[foundIndex].totalprice =
            cart[foundIndex].quantity * cart[foundIndex].price;
            setCookie(CONSTANTS.cartCookie, JSON.stringify(cart));
            setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
            loadTotalPayment();
        }
    };

    const loadTotalPayment = () => {
      setSubtotal(calculateTotalPrice(cart));
    };

    useEffect(() => {
        setTotalPayment(subtotal);
    }, [subtotal]);

    useEffect(() => {
        setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
        if(cart) {
            loadTotalPayment();
        }
    }, [cart]);

    return (
        <section className="max-w-screen-2xl mx-auto">
            {cart ? (
                <div>
                    <div className='bg-white'>
                        {cart?.map((_,i) => (
                            <div className="py-5 border-b border-slate-200 grid grid-cols-7 gap-2 flex items-center">
                                <p className="col-span-2"><Image src={JSON.parse(_?.product_image)?.filter((img) => img.type === 'image')?.[0]?.data} /></p>
                                <div className="col-span-4">
                                    <p>{_?.product_name}</p>
                                    <p className="text-[#FF424E] font-semibold">{FormatVND(_?.totalprice)}</p>
                                    <p>
                                        <InputGroup 
                                            count={_?.quantity} 
                                            setCount={(e) => {
                                                onChangeQuantityHandler(
                                                    e.target.value,
                                                    _?.product_id
                                                )
                                            }} 
                                            onClickButtonMinus={()=> {
                                                onChangeQuantityHandler(
                                                    _?.quantity - 1,
                                                    _?.product_id
                                                )
                                            }}
                                            onClickButtonPlus={() => 
                                                {(_?.quantity >= _?.characteristics.total) ?
                                                onChangeQuantityHandler(
                                                    _?.quantity + 1,
                                                    _?.product_id
                                                ) : (
                                                    message.info("Số lượng bạn chọn lớn hơn số hàng còn lại trong kho!")
                                                )}
                                            }
                                        />
                                    </p>
                                </div>
                                <p>
                                    <DeleteOutlined 
                                        className="text-red-500 text-xl"
                                        onClick={()=> {
                                            dispatch(deleteProduct(_));
                                            setCart(JSON.parse(getCookie(CONSTANTS.cartCookie)));
                                        }}              
                                    />
                                </p>
                            </div>
                        ))}
                    </div>

                    <div className='my-1 p-5 bg-white'> 
                        <div className="flex justify-between items-center px-20">
                            <p className="text-[16px] font-semibold">Tổng tiền tạm tính:</p>
                            <p className="text-[#FF424E] font-semibold text-lg">{FormatVND(subtotal)}</p>
                        </div>
                    </div>

                    <div className="flex justify-between my-5">
                        <button className="border px-4 py-2 bg-white font-semibold hover:bg-gray-50" onClick={()=>navigate("/")}>Tiếp tục mua hàng</button>
                        <button className="border px-4 py-2 bg-blue-500 text-white font-semibold hover:bg-blue-400" onClick={()=>navigate("/thanh-toan")}>Mua hàng</button>
                    </div>
                </div>
            ) : (
                <Empty />
            )}
        </section>
    )
}