import { message } from "antd";
import axiosInstance from "../utils/axios";

export const fetchAll = async (setData) => {
    try {
        await axiosInstance.get(`/iframe-page/getAll`)
          .then((res) => {
            const data = res?.data;
            setData(data);
          })
          .catch(() => message.error("Error server!"));
    } catch (error) {
        console.log(error);
    }
};

export const update = async (id, data, message) => {
    try {
        await axiosInstance.post(`/iframe-page/update/${id}`, data)
          .then((res) => {
            const data = res?.data;
            message.info(String(data?.message));
          })
          .catch(() => message.error("Error server!"));
    } catch (error) {
        console.log(error);
    }
};