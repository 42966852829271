import ItemCategory from '../../section/itemCategory';
import { useParams } from 'react-router-dom';
import ItemProductGrid from '../../section/itemProductGrid';
import { useEffect, useState } from 'react';
import { getAll, getAllByCategoryIndexId } from '../../api/category';
import { Button, Select } from 'antd';

export default function CategoryIndexIsMobile() {
    const param = useParams();
    const [topBuy, setTopBuy] = useState(false);
    const [price, setPrice] = useState(0);
    const [categoryIndex, setCategoryIndex] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(10);

    useEffect(() => {
        let query = `page=1&pageSize=${itemsToShow}`;
        if (topBuy) { query += `&topBuy=1`; }
        if (price > 0) { query += `&filPrice=${price}`; }
        getAllByCategoryIndexId(param.id, query, setCategoryIndex);
    }, [itemsToShow, topBuy, price]);

    const handleLoadMore = () => {
        setItemsToShow(prev => prev + 10);
    };

    return (
        <div className="max-w-screen-2xl mx-auto pt-20">
            <div className='my-2 p-5 bg-white rounded-xl'>
                {categoryIndex?.category ? (
                    <img className='h-10' src={categoryIndex?.category?.[0]?.category_index_image} />
                ) : (
                    <p className='font-bold text-2xl'>Gợi ý hôm nay</p>
                )}
            </div>

            <div className='my-2 p-5 bg-white rounded-xl'>
                <div className='flex items-center'>
                    <div>
                        <button className={`border px-4 py-1 mx-4 font-normal rounded-lg ${topBuy ? "bg-blue-500 text-white" : ""}`} onClick={() => { setTopBuy(!topBuy); setPrice(0); }}>Bán Chạy</button>
                        <Select
                            className="w-[180px]"
                            onChange={(e) => { setPrice(e); setTopBuy(false); }}
                            options={[
                                { label: "Giá", value: 0 },
                                { label: "Giá: Từ thấp đến cao", value: 1 },
                                { label: "Giá: Từ cao đến thấp", value: 2 }
                            ]}
                            value={price}
                        />
                    </div>
                </div>
                <div className="w-full h-full mt-5 pb-10 relative">
                    <ItemProductGrid product={categoryIndex?.data?.slice(0, itemsToShow)} grid={2} titleSize={13} heightTitle={10} />
                    
                    <div className='flex justify-center pt-5'>
                        {itemsToShow < categoryIndex?.total && (
                            <button className='border px-4 py-2 font-semibold text-lg text-blue-600 border-blue-600 hover:bg-blue-100' onClick={handleLoadMore}>Xem thêm</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}