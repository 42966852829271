import {
    Form,
    Row,
    Input,
    Space,
    Button,
    message,
    InputNumber,
    Select,
    Modal,
  } from "antd";
  import { useEffect, useState } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import { ExclamationCircleOutlined } from "@ant-design/icons";
  import axiosInstance from "../../utils/axios";
  
  export default function WareHouseForm({
    id = "",
    initialValues = {},
    onSubmit = () => {},
  }) {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [product, setProduct] = useState([]);
    const [attribute, setAttribute] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 100,
    });

    const fetchProduct = async () => {
        await axiosInstance.get(`/product/getAll`, {params: pagination})
        .then((res) => {
            const data = res?.data;
            setProduct(data);
        })
        .catch(() => message.error("Error server!"));
    };

    const handleProductChange = async (e) => {
        form.setFieldsValue({ characteristics_hash: null });
        await axiosInstance.get(`/product/getById/${e}`, {params: pagination})
        .then((res) => {
            const data = res?.data;
            setAttribute(data?.data?.attribute);
        })
        .catch(() => message.error("Error server!"));
    }
  
    const deleteWareHouse = async () => {
      await axiosInstance.delete(`/warehouse/delete/${id}`)
        .then((res) => message.success(String(res?.data?.message)))
    };
  
    useEffect(() => {
        fetchProduct();
        if (Object.keys(initialValues)?.length > 0) {
            form.resetFields();
        }
    }, [form, initialValues]);
  
    const removeWareHouse = async () => {
      try {
        await deleteWareHouse();
        return navigate("/admin/quan-ly-kho-hang");
      } catch (err) {
        console.log(err.message);
      }
    };
  
    const confirmDeleteBusiness = () => {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: "Bạn có chắc chắn xoá sản phẩm này khỏi kho?",
        okText: "Xác nhận",
        cancelText: "Huỷ",
        onOk: () => removeWareHouse(),
      });
    };
    return (
      <div className={"p-[40px] bg-white rounded-[10px]"}>
        <div className={"!text-[#2d2e32] pb-[10px]"}>
          <Link
            to={"/admin/quan-ly-thuoc-tinh"}
            className={
              "text-[18px] sm:text-[24px] md:text-[26px] xl:text-[26px] font-[500] cursor-pointer "
            }
          >
            {"Thông tin sản phẩm"}
          </Link>
        </div>
  
        <Form
          layout={"vertical"}
          colon={false}
          form={form}
          initialValues={initialValues}
          onFinishFailed={(e) => console.log(e)}
          onFinish={onSubmit}
        >            
            <div className="grid grid-cols-2 gap-4">
                <Form.Item label={"Sản phẩm"} name="product_id">
                  <Select
                      showSearch
                      filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      size="large"
                      placeholder="Select a person"
                      optionFilterProp="children"
                      disabled={Object.keys(initialValues)?.length > 0}
                      onChange={handleProductChange}
                      options={product?.data?.map((value) => ({
                          value: value.product_id,
                          label: value.product_name,
                      }))}
                  />
                </Form.Item>
                
                <Form.Item label={"Thuộc tính sản phẩm"} name="characteristics_hash">
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        size="large"
                        placeholder="Select a person"
                        optionFilterProp="children"
                        disabled={attribute?.length === 0}
                        options={attribute?.map((value) => ({
                            value: value.characteristics_hash,
                            label: value.values,
                        }))}
                    />
                </Form.Item>
            </div>
            <Form.Item
                label={"Số lượng"}
                name="total"
                rules={[{ required: true, message: "Vui lòng nhập số lượng!" }]}
            >
                <Input type="number" size="large" placeholder={"Nhập"} />
            </Form.Item>
  
            <Row gutter={40} className={"my-[40px] pl-[20px]"}>
                <Space align="center">
                <Button type={"primary"} htmlType={"submit"}>
                    {id && id !== "tao-moi" ? "Cập nhập" : "Tạo"}
                </Button>
                {id && id !== "tao-moi" && (
                    <Button type={"primary"} danger onClick={confirmDeleteBusiness}>
                        Xoá
                    </Button>
                )}
                </Space>
            </Row>
        </Form>
      </div>
    );
  }
  