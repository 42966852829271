import ItemCategory from '../../section/itemCategory';
import { useParams } from 'react-router-dom';
import ItemProduct from '../../section/itemProduct';
import { useEffect, useState } from 'react';
import { getAll, getAllByCategoryIndexId } from '../../api/category';
import ItemProductGrid from '../../section/itemProductGrid';
import { Col, Pagination, Row, Select } from 'antd';
import { useDevice } from '../../hooks';
import CategoryIndexIsMobile from './categoryIndexIsMobile';

export default function CategoryIndex() {
    const { isMobile } = useDevice();
    const param = useParams();
    const [isFixed, setIsFixed] = useState(false);
    const [topBuy, setTopBuy] = useState(false);
    const [price, setPrice] = useState(0);
    const [category, setCategory] = useState([]);
    const [categoryIndex, setCategoryIndex] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(10);

    useEffect(() => {
        let query = `page=1&pageSize=${itemsToShow}`;
        if (topBuy) { query += `&topBuy=1`; }
        if (price > 0) { query += `&filPrice=${price}`; }
        getAllByCategoryIndexId(param.id, query, setCategoryIndex);
    }, [itemsToShow, topBuy, price]);

    const handleLoadMore = () => {
        setItemsToShow(prev => prev + 10);
    };
    
    useEffect(() => {
        getAll("page=1&pageSize=100", setCategory);
        const handleScroll = () => {
            setIsFixed(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="max-w-screen-2xl mx-auto">
            {isMobile ? <CategoryIndexIsMobile /> : (
                <Row>
                    <Col xs={24} xl={4} className='pr-2'>
                        <div 
                            className={`my-2 p-5 bg-white rounded-lg h-[100vh] ${isFixed ? "fixed top-0" : "overflow-y-visible"}`} 
                            style={{
                                scrollbarWidth: 'none'
                            }}
                        >
                            {category?.data?.map((_,i)=> (
                                <a href={`/danh-muc-san-pham/${_?.category_slug}`} className='flex items-center p-2 hover:bg-gray-200 rounded-xl'>
                                    <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                    <p className='pl-2 text-black w-[150px]'>{_?.category_name}</p>
                                </a>
                            ))}
                        </div>
                    </Col>
                    <Col xs={24} xl={20}>
                        <div className='my-2 p-5 bg-white rounded-xl'>
                            {categoryIndex?.category ? (
                                <img className='h-12' src={categoryIndex?.category?.[0]?.category_index_image} />
                            ) : (
                                <p className='font-bold text-2xl'>Gợi ý hôm nay</p>
                            )}
                        </div>
                        
                        <div className='my-2 p-5 bg-white rounded-xl'>
                            <div className='flex items-center'>
                                <p className='font-semibold'>Sắp xếp theo:</p>
                                <div className='px-2'>
                                    <button className={`border px-4 py-1 mx-4 font-normal rounded-lg ${topBuy ? "bg-blue-500 text-white " : ""}`} onClick={()=>{setTopBuy(!topBuy); setPrice(0)}}>Bán Chạy</button>
                                    <Select
                                        className="w-[180px]"
                                        onChange={(e)=>{setPrice(e); setTopBuy(false)}}
                                        options={[
                                            {
                                                label: "Giá",
                                                value: 0
                                            },
                                            {
                                                label: "Giá: Từ thấp đến cao",
                                                value: 1
                                            },
                                            {
                                                label: "Giá: Từ cao đến thấp",
                                                value: 2
                                            }
                                        ]}
                                        value={price}
                                    />
                                </div>
                            </div>
                            <div className="w-full h-full mt-5 pb-10 relative">
                                <ItemProductGrid product={categoryIndex?.data} grid={5} titleSize={13} heightTitle={10}/>
                                
                                <div className='flex justify-center pt-10'>
                                    {itemsToShow < categoryIndex?.total && (
                                        <button className='border px-4 py-2 font-semibold text-lg text-blue-600 border-blue-600 hover:bg-blue-100' onClick={handleLoadMore}>Xem thêm</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    )
}